import * as Yup from 'yup'
import i18n from '../i18n'

// Interpreter POST Schema
export const interpreterSchema: Yup.ObjectSchema<IInterpreterSchema> = Yup.object({
	language: Yup.string().required(i18n.t('validation:interpreter.languageRequired')),
	name: Yup.string().required(i18n.t('validation:interpreter.nameRequired')),
	description: Yup.string().required(i18n.t('validation:interpreter.descriptionRequired')),
	focus: Yup.string().required(i18n.t('validation:interpreter.focusRequired')),
	benefit: Yup.string().required(i18n.t('validation:interpreter.benefitRequired')),
	prompt: Yup.string().required(i18n.t('validation:interpreter.promptRequired')),
	icon: Yup.string().required(i18n.t('validation:interpreter.iconRequired')),
	image: Yup.string().nullable().notRequired(),
	active: Yup.boolean().required(i18n.t('validation:interpreter.activeRequired')),
	menuOrder: Yup.number().min(0, i18n.t('validation:interpreter.menuOrderMin')).typeError(i18n.t('validation:interpreter.menuOrderTypeError')).required(i18n.t('validation:interpreter.menuOrderRequired'))
}).required()

