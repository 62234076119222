import './PublicHeader.scss'

// Atoms
import Logo from '../../../ruya-shared/shared/ui/atoms/logo/Logo'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Shared
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const PublicHeader = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()

	const login = () => {
		navigate(commonSettings.apps.admin.paths.login)
	}

	return (
		<header className="PublicHeader">
			<Logo />
		</header>
	)
}

export default PublicHeader
