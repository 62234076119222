import { useEffect } from 'react'
import './Statistics.scss'

// Store
import useStatisticsStore from '../../../store/statisticsStore'

// Atoms
import H1 from '../../../ruya-shared/shared/ui/atoms/h1/H1'

const Statistics = () => {
	const statisticsStore = useStatisticsStore()

	useEffect(() => {
		// Load statistics
		statisticsStore.loadStatistics()
	}, [])

	return (
		<div className="Statistics">
			<H1>Total Statistics</H1>
			<table className="Statistics_Table">
				<thead>
					<tr>
						<th>Users</th>
						<th>Sleep Sessions</th>
						<th>Dreams</th>
						<th>Interpretations</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{statisticsStore?.statistics?.totalUsers}</td>
						<td>{statisticsStore?.statistics?.totalSleepSessions}</td>
						<td>{statisticsStore?.statistics?.totalDreams}</td>
						<td>{statisticsStore?.statistics?.totalInterpretations}</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

export default Statistics
