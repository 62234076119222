import type React from 'react'
import { useState, type ChangeEvent, forwardRef } from 'react'
import './FileInput.scss'

// Locales
import { useTranslation } from 'react-i18next'

// Atoms
import Tooltip from '../../../ruya-shared/shared/ui/atoms/tooltip/Tooltip'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

interface FileInputProps {
	invalid?: boolean
	size?: string
	valid?: boolean
	tooltipText?: string
	showTooltip?: boolean
	className?: string
	name: string
	label?: string
	placeholder?: string
	defaultFilename?: string
	buttonText?: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	loading?: boolean
	showIcon?: boolean
	icon?: string
	warning?: boolean
	disabled?: boolean
	multiple?: boolean
	[key: string]: any // This is to accommodate any other additional properties passed to the component
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
	const {
		invalid,
		size,
		valid,
		tooltipText = '',
		showTooltip = false,
		className,
		name = 'file',
		label,
		placeholder,
		defaultFilename,
		buttonText,
		onChange = () => {},
		loading,
		showIcon = true,
		icon,
		warning,
		...rest
	} = props

	// Classes
	const classNames =
		'FileInput' +
		`${valid ? ' FileInput--valid' : ''}` +
		`${showIcon ? ' FileInput--hasIcon' : ''}` +
		`${props.disabled ? ' FileInput--disabled' : ''}` +
		`${loading ? ' FileInput--loading' : ''}` +
		`${size ? ` FileInput--${size}` : ''}` +
		`${warning ? ' FileInput--warning' : ''}` +
		`${invalid ? ' FileInput--invalid' : ''}` +
		`${className !== undefined ? ' ' + className : ''}`

	// Local state variables
	const [fileList, setFileList] = useState<FileList | object>({})

	// Translation
	const { t } = useTranslation()

	// Sets the files
	const handleFileSelection = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target
		if (files?.length) {
			setFileList(files)
			onChange(e)
		}
	}

	// List of files
	const Files: React.FC = () => {
		// Return if fileList has items
		if (fileList instanceof FileList && fileList.length > 0)
			return (
				<>
					{Array.from(fileList).map((file, i) => (
						<span key={name + i}>{file.name}</span>
					))}
				</>
			)

		// Return default file name if provided
		if (defaultFilename) return <> {defaultFilename} </>

		// Return placeholder text
		return <> {placeholder || t('form:fileInput.placeholder')} </>
	}

	return (
		<div className={classNames}>
			{showIcon && (
				<div className="FileInput_Icon">
					<MaterialSymbol name={icon || 'upload_file'} />
				</div>
			)}

			<div className="FileInput_Info">
				<div className="FileInput_Label">
					{label || (props.multiple ? t('form:fileInput.labelPlural') : t('form:fileInput.label'))}
					{showTooltip && (
						<Tooltip content={tooltipText} disabled={tooltipText.length <= 0}>
							<MaterialSymbol name="help" />
						</Tooltip>
					)}
				</div>

				<div className="FileInput_Files">
					<Files />
				</div>
			</div>

			<div className="FileInput_Button">
				<label className="FileInput_ButtonText" htmlFor={name}>
					{loading ? (
						<MaterialSymbol name="progress_activity" className="css-spin" />
					) : (
						buttonText || t('form:fileInput.buttonText')
					)}
				</label>
				<Input className="FileInput_Input" ref={ref} name={name} id={name} onChange={handleFileSelection} type="file" {...rest} />
			</div>
		</div>
	)
})

FileInput.displayName = 'FileInput'
export default FileInput
