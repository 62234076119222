import { useEffect, useState } from 'react'
import './EditArticleAudio.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import { FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import AdminArticleNavigation from '../../molecules/adminArticleNavigation/AdminArticleNavigation'

// App Settings
import appSettings from '../../../appSettings'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Confirm Dialog
import { confirmAlert } from 'react-confirm-alert'

// Shared
import type { IArticle } from '../../../ruya-shared/shared/types'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditArticleAudio = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isGenerating, setIsGenerating] = useState<boolean>(false)
	const [post, setPost] = useState<IArticle | null>(null)
	const [selectedAudioModel, setSelectedAudioModel] = useState<string | undefined>(
		appSettings.ai.audioModels.find(model => model.isDefault)?.modelName
	)
	const [selectedAudioVoice, setSelectedAudioVoice] = useState<string | undefined>(
		appSettings.ai.audioVoices.find(model => model.isDefault)?.voice
	)

	// Get Post by ID with api call
	useEffect(() => {
		if (!id) navigate('/article')
		getPost()
	}, [id])

	// Get Post by ID
	const getPost = async () => {
		setIsLoading(true)

		try {
			const response = await apiProtected.get(`admin/article/${id}`)

			if (response.data.status === 'success') {
				const articleData = response.data.data as IArticle
				// Update state
				setPost(articleData)
				setIsLoading(false)
			} else {
				setApiError(response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Upload finished
	const handleGenerateAudio = () => {
		if (post?.audio) {
			confirmAlert({
				title: t('dialog:warningTitle'),
				message: t('dialog:overwriteAudio'),
				buttons: [
					{
						label: t('button:yes'),
						onClick: () => generateAudio(),
						className: 'button--danger'
					},
					{
						label: t('button:cancel'),
						onClick: () => {
							return false
						}
					}
				]
			})
		} else {
			generateAudio()
		}
	}

	const handleDeleteAudio = () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteAudio'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => deleteAudio(),
					className: 'button--danger'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	const generateAudio = async () => {
		setIsGenerating(true)

		try {
			const response = await apiProtected.post('admin/ai/audio', {
				articleId: id,
				modelName: selectedAudioModel,
				voice: selectedAudioVoice
			})

			if (response.data.status === 'success') {
				setIsGenerating(false)
				getPost()
			} else {
				setApiError(response.data.message)
				setIsGenerating(false)
			}
		} catch (error) {
			setIsGenerating(false)
			setApiError(apiErrorHandler(error))
		}
	}

	const deleteAudio = async () => {
		setIsGenerating(true)

		try {
			const response = await apiProtected.delete(`file-manager/delete-file/${post?.audio}`)

			if (response.data.status === 'success') {
				setIsGenerating(false)
				getPost()
			} else {
				setApiError(response.data.message)
				setIsGenerating(false)
			}
		} catch (error) {
			setIsGenerating(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Loading
	if (isLoading)
		return (
			<div className="EditArticleAudio">
				<Loading />
			</div>
		)

	return (
		<main className="EditArticleAudio">
			<h1 className="Admin_H1">{t('admin:headerEditArticleAudio')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${post?.lang}/articles/${post?.slug}`} />

			<AdminArticleNavigation id={id} lang={post?.lang} />

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<div className="EditArticleAudio_Settings">
				<FormFieldSet className="AdminEditArticleSettings_BulkOperations" legend={t('admin:audioHeader')}>
					{post?.audio ? (
						<div className="EditArticleAudio_Audio">
							<audio controls>
								<source src={commonSettings.apps.cdn.url + '/' + post?.audio} type="audio/mpeg" />
								Your browser does not support the audio element.
							</audio>
						</div>
					) : (
						<>
							<InfoBox text={t('admin:noAudio')} />
						</>
					)}
				</FormFieldSet>

				{post?.audio && (
					<FormFieldSet className="AdminEditArticleSettings_DangerZone" legend={t('admin:audioDeleteHeader')}>
						<div className="EditArticleAudio_Audio">
							<Button text={t('button:deleteAudio')} icon="delete" color="error" onClick={handleDeleteAudio} />
						</div>
					</FormFieldSet>
				)}

				<FormFieldSet className="AdminEditArticleSettings_BulkOperations" legend={t('admin:audioGenerateHeader')}>
					<FormGroup>
						<InfoBox type="info" icon="true" text={t('admin:audioGenerateDesc')} />
					</FormGroup>
					<FormGroup className="EditArticleAudio_Generate">
						{/* <Select
							label={t('form:audioModel.label')}
							inputSize="sm"
							keyField="modelName"
							valueField="modelName"
							labelField="modelName"
							disabled={isGenerating}
							options={appSettings.ai.audioModels}
							value={selectedAudioModel}
							onChange={(e: any) => setSelectedAudioModel(e.target.value)}
						/> */}
						<Select
							label={t('form:voice.label')}
							inputSize="sm"
							keyField="voice"
							valueField="voice"
							labelField="label"
							disabled={isGenerating}
							options={appSettings.ai.audioVoices}
							value={selectedAudioVoice}
							onChange={(e: any) => setSelectedAudioVoice(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Button
							text={post?.audio ? t('button:generateAndOverwriteAudio') : t('button:generateAudio')}
							icon="interpreter_mode"
							color={post?.audio ? 'warning' : 'success'}
							disabled={isGenerating}
							loading={isGenerating}
							onClick={handleGenerateAudio}
						/>
					</FormGroup>
				</FormFieldSet>
			</div>
		</main>
	)
}

export default Admin(EditArticleAudio)
