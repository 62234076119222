import { useEffect } from 'react'

// Zustand
import useUserStore from '../../../store/userStore'

// React Router
import { useNavigate } from 'react-router-dom'

// Shared
import { setLocalStorage } from '../../../ruya-shared/shared/utils/storageHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

const Logout = () => {
	// Zustand
	const userStore = useUserStore()

	// Router
	const navigate = useNavigate()

	useEffect(() => {
		// Remove token from local storage
		setLocalStorage(commonSettings.apps.api.tokenName, '')

		// Reset user state
		userStore.reset()

		// Navigate user to login page
		navigate(commonSettings.apps.admin.paths.login, { replace: true })
	}, [])

	return null
}

export default Logout
