// This Higher Order Component wrap pages with main tag.
import type { ComponentType, FC } from 'react'
import './Public.scss'

// Organisms
import PublicHeader from '../../organisms/publicHeader/PublicHeader'

// Type declarations
type ComponentProps = JSX.IntrinsicAttributes
type PublicWrapperProps<P> = P & ComponentProps

function Public<P extends ComponentProps>(PageComponent: ComponentType<P>): FC<P> {
	const PageWrapper: FC<PublicWrapperProps<P>> = props => {
		return (
			<div className="Public">
				<PublicHeader />

				<PageComponent {...props} />
			</div>
		)
	}

	PageWrapper.displayName = `Public(${PageComponent.displayName || PageComponent.name || 'Component'})`

	return PageWrapper
}

export default Public
