import * as Yup from 'yup'
import i18n from '../i18n'

// Regular Expressions
import { folderNameRegEx } from '../ruya-shared/shared/validation/regularExpressions'

// Login Validation Schema
export const newFolderSchema: Yup.ObjectSchema<INewFolderSchema> = Yup.object({
	folderName: Yup.string().required(i18n.t('form:folderName.required')).matches(folderNameRegEx, i18n.t('form:folderName.format'))
}).required()
