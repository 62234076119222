export function serializeFieldErrors(errors: any): string {
	const serializeErrorObject = (errorObject: any): any => {
		if (typeof errorObject !== 'object' || errorObject === null) {
			return errorObject
		}

		// Avoid serializing non-primitive types like DOM elements
		if (errorObject instanceof Element) {
			return `Element(${errorObject.tagName})`
		}

		const serializedObject: any = Array.isArray(errorObject) ? [] : {}
		for (const key in errorObject) {
			if (Object.prototype.hasOwnProperty.call(errorObject, key)) {
				serializedObject[key] = serializeErrorObject(errorObject[key])
			}
		}
		return serializedObject
	}

	return JSON.stringify(serializeErrorObject(errors))
}
