import { useEffect, useState } from 'react'
import './EditPage.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { pageUpdateSchema } from '../../../validation/pageSchema'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Molecules
import ArticleEditor from '../../molecules/articleEditor/ArticleEditor'
import AdminPageNavigation from '../../molecules/adminPageNavigation/AdminPageNavigation'
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Zustand
import usePageStore from '../../../store/pageStore'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Toastify
import { toast } from 'react-toastify'

// Shared
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'
import { serializeFieldErrors } from '../../../ruya-shared/shared/utils/formHelper'
import type { IPage } from '../../../ruya-shared/shared/types'

const EditPage = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const pageStore = usePageStore()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [content, setContent] = useState<IPage | null>(null)

	// Get language object
	const languageObject = getActiveLanguages.find(lang => lang.isoCode === content?.lang)

	// Is default language
	// const isDefaultLanguage = content?.lang === languageSettings.defaultLanguage

	// Input direction (ltr or rtl) depending on the language
	const dir = languageObject?.direction || 'ltr'

	// Is content has translations
	// const isContentHasTranslations: boolean = (content?.languageRelations?.length || 0) > 0

	const initialFormValues = {
		_id: '',
		lang: '',
		slug: '',
		linkLabel: '',
		title: '',
		content: '',
		metaTitle: '',
		metaDescription: '',
		metaKeywords: '',
		showInMainMenu: false,
		showInFooterMenu: false,
		menuOrder: 0
	}

	const formOptions = { mode: 'onChange', defaultValues: initialFormValues, resolver: yupResolver(pageUpdateSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		getValues,
		reset,
		setValue,
		formState: { errors, isDirty }
	} = useForm<IPage>(formOptions)

	// Get Content by ID with api call
	useEffect(() => {
		if (!id) navigate('/page')
		getContent()
	}, [id])

	const getContent = async () => {
		try {
			const response = await apiProtected.get(`admin/page/${id}`)
			if (response.data.status === 'success') {
				const contentData = response.data.data

				setContent(contentData)

				reset({
					_id: contentData._id,
					lang: contentData.lang,
					slug: contentData.slug,
					linkLabel: contentData.linkLabel,
					title: contentData.title,
					content: contentData.content,
					metaTitle: contentData.metaTitle,
					metaDescription: contentData.metaDescription,
					metaKeywords: contentData.metaKeywords,
					showInMainMenu: contentData.showInMainMenu,
					showInFooterMenu: contentData.showInFooterMenu,
					menuOrder: contentData.menuOrder
				})
			} else {
				navigate('/page')
			}
		} catch (error) {
			console.error('Failed to fetch page data:', error)
			navigate('/page')
		}
	}

	useEffect(() => {
		getContent()
	}, [])

	// Watch
	const slugValue = watch('slug')
	const langValue = watch('lang')

	// Form submit
	const onSubmit = async (values: IPage) => {
		// Set loading
		setIsLoading(true)

		// Clear API error
		setApiError(null)

		// Update content
		const response = await pageStore.updatePage(values)

		if (response.status === 'success' && response.data) {
			const contentData = response.data as unknown as IPage

			// Show success message
			toast.success('Content updated!', {
				position: 'bottom-center',
				autoClose: 2000
			})

			// Update state
			setContent(contentData)

			reset({
				_id: contentData._id,
				lang: contentData.lang,
				slug: contentData.slug,
				linkLabel: contentData.linkLabel,
				title: contentData.title,
				content: contentData.content,
				metaTitle: contentData.metaTitle,
				metaDescription: contentData.metaDescription,
				metaKeywords: contentData.metaKeywords,
				showInMainMenu: contentData.showInMainMenu,
				showInFooterMenu: contentData.showInFooterMenu,
				menuOrder: contentData.menuOrder
			})
		} else {
			setApiError(response.message || t('error:unexpectedError'))
		}
		setIsLoading(false)
	}

	return (
		<main className="EditPage">
			<h1 className="Admin_H1">{t('admin:headerEditPage')}</h1>

			<HistoryBackLink />

			<AdminPageNavigation id={id} lang={content?.lang} />

			<Form className="EditPage_Form" noValidate>
				<input type="hidden" {...register('_id')} />

				<FormGroup>
					<InfoBox icon="true" type="info" text={`http://ruya.co/${langValue}/pages/${slugValue}`} />
				</FormGroup>

				<FormGroup fourColumn={true}>
					<Input
						inputSize="sm"
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.lang?.message)}
						invalidMessage={errors?.lang?.message?.toString()}
						disabled={true}
						{...register('lang')}
					/>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:pageTag.placeholder')}
						label={t('form:pageTag.label')}
						invalid={Boolean(errors?.pageTag?.message)}
						invalidMessage={errors?.pageTag?.message?.toString()}
						{...register('pageTag')}
					/>
					<Input
						inputSize="sm"
						icon="link"
						type="text"
						placeholder={t('form:slug.placeholder')}
						label={t('form:slug.label')}
						invalid={Boolean(errors?.slug?.message)}
						invalidMessage={errors?.slug?.message?.toString()}
						{...register('slug')}
					/>
					<Input
						inputSize="sm"
						icon="link"
						type="text"
						placeholder={t('form:linkLabel.placeholder')}
						label={t('form:linkLabel.label')}
						invalid={Boolean(errors?.linkLabel?.message)}
						invalidMessage={errors?.linkLabel?.message?.toString()}
						{...register('linkLabel')}
					/>
				</FormGroup>

				<FormGroup twoColumn={true}>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaTitle.placeholder')}
						label={t('form:metaTitle.label')}
						invalid={Boolean(errors?.metaTitle?.message)}
						invalidMessage={errors?.metaTitle?.message?.toString()}
						{...register('metaTitle')}
					/>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaDescription.placeholder')}
						label={t('form:metaDescription.label')}
						invalid={Boolean(errors?.metaDescription?.message)}
						invalidMessage={errors?.metaDescription?.message?.toString()}
						{...register('metaDescription')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						inputSize="sm"
						type="text"
						placeholder={t('form:metaKeywords.placeholder')}
						label={t('form:metaKeywords.label')}
						invalid={Boolean(errors?.metaKeywords?.message)}
						invalidMessage={errors?.metaKeywords?.message?.toString()}
						{...register('metaKeywords')}
					/>
				</FormGroup>

				<FormGroup threeColumn={true}>
					<Switch label={t('form:showInMainMenu.label')} defaultValue={watch('showInMainMenu')} {...register('showInMainMenu')} />
					<Switch
						label={t('form:showInFooterMenu.label')}
						defaultValue={watch('showInFooterMenu')}
						{...register('showInFooterMenu')}
					/>
					<Input
						inputSize="sm"
						type="number"
						placeholder={t('form:menuOrder.placeholder')}
						label={t('form:menuOrder.label')}
						invalid={Boolean(errors?.menuOrder?.message)}
						invalidMessage={errors?.menuOrder?.message?.toString()}
						{...register('menuOrder')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.title?.message)}
						invalidMessage={errors?.title?.message?.toString()}
						{...register('title')}
					/>
				</FormGroup>

				<FormGroup>
					<ArticleEditor
						contentCss="../../../tinymce/content.css"
						defaultFolder={`content/${id}`}
						dir={dir}
						value={watch('content')}
						{...register('content')}
					/>
				</FormGroup>

				{apiError && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					{errors && Object.keys(errors).length > 0 && <InfoBox icon="true" type="error" text={serializeFieldErrors(errors)} />}

					<Button
						text={t('button:save')}
						color="success"
						loading={isLoading}
						type="button"
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(EditPage)
