// Molecules
import TabLinkNavigation from '../tabLinkNavigation/TabLinkNavigation'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

// Localization
import { useTranslation } from 'react-i18next'

// Utils
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

interface AdminArticleNavigationProps {
	id?: string
	lang?: string
}

const AdminArticleNavigation = (props: AdminArticleNavigationProps) => {
	const { id, lang } = props

	// Translation Hook
	const { t } = useTranslation()

	// Check if language
	const isDefaultLanguage = languageSettings.defaultLanguage === lang
	const isAudioEnable = getActiveLanguages.find(language => language.isoCode === lang)?.audioGeneration

	return (
		<TabLinkNavigation>
			<ReactLink href={`/article/edit/${id}`}>{t('admin:article')}</ReactLink>
			{isDefaultLanguage && <ReactLink href={`/article/edit/${id}/images`}>{t('admin:image')}</ReactLink>}
			{isAudioEnable && <ReactLink href={`/article/edit/${id}/audio`}>{t('admin:audio')}</ReactLink>}
			{isDefaultLanguage && <ReactLink href={`/article/edit/${id}/translations`}>{t('admin:translations')}</ReactLink>}
			{isDefaultLanguage && <ReactLink href={`/article/edit/${id}/settings`}>{t('admin:settings')}</ReactLink>}
		</TabLinkNavigation>
	)
}

export default AdminArticleNavigation
