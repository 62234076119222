import './Pagination.scss'

// Localization
import { useTranslation } from 'react-i18next'

// Molecules
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

interface PaginationProps {
	lang: string
	page: number
	totalPages: number
	className?: string
	prevNext?: boolean
	pageNumbers?: boolean
	onPageChange: (page: number) => void
}

export default function Pagination(props: PaginationProps) {
	// Destruct props
	const { lang, page, totalPages, prevNext = true, pageNumbers = true, onPageChange = () => {}, className } = props

	// Translation Hook
	const { t } = useTranslation()

	// If no page or totalPages, or if both prevNext and pageNumbers are false, return null
	if (!page || !totalPages || (!prevNext && !pageNumbers)) return null

	// Class names
	const classNames = `Pagination${className ? ' ' + className : ''}`

	// If total pages is 1, return null
	if (totalPages <= 1) return null

	// Create pagination links
	const Links = () => {
		const links = []
		for (let i = 1; i <= totalPages; i++) {
			links.push(
				<ReactLink
					key={i}
					onClick={() => onPageChange(i)}
					href="#"
					lang={lang}
					className="Pagination_Links_Link"
					active={i === page}
					disabled={i === page}>
					{i}
				</ReactLink>
			)
		}
		return links
	}

	return (
		<div className={classNames}>
			{prevNext && (
				<div className="Pagination_NextPrev">
					<ReactLink
						onClick={() => onPageChange(page - 1)}
						href="#"
						lang={lang}
						className="Pagination_NextPrev_Prev"
						disabled={page <= 1}>
						{t('links:previous')}
					</ReactLink>

					<ReactLink
						onClick={() => onPageChange(page + 1)}
						href="#"
						lang={lang}
						className="Pagination_NextPrev_Next"
						disabled={page >= totalPages}>
						{t('links:next')}
					</ReactLink>
				</div>
			)}

			{pageNumbers && (
				<div className="Pagination_Links">
					<Links />
				</div>
			)}
		</div>
	)
}
