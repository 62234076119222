import { useEffect, useState } from 'react'
import './EditPageSettings.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import { FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Molecules
import AdminPageNavigation from '../../molecules/adminPageNavigation/AdminPageNavigation'
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Confirmation dialog
import { confirmAlert } from 'react-confirm-alert'

// Toastify
import { toast } from 'react-toastify'

// Shared
import type { IPage } from '../../../ruya-shared/shared/types'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditPageSettings = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [content, setContent] = useState<IPage | null>(null)
	const [pageTag, setPageTag] = useState<string>('')
	const [menuOrder, setMenuOrder] = useState<number>(0)

	// Get Content by ID with api call
	useEffect(() => {
		if (!id) navigate('/page')
		getContent()
	}, [id])

	// Get content by ID
	const getContent = async () => {
		setIsLoading(true)

		try {
			const response = await apiProtected.get(`admin/page/${id}`)

			if (response.data.status === 'success') {
				const contentData = response.data.data as IPage
				// Update state
				setContent(contentData)
				setPageTag(contentData.pageTag)
				setMenuOrder(contentData.menuOrder)
				setIsLoading(false)
			} else {
				setApiError(response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Handle status change
	const handleStatus = async (status: string) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeStatus', { status }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/page/status', {
								_id: id,
								status
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationStatus', { status }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle isFeatured change
	const handleIsFeatured = async (isFeatured: boolean) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeIsFeatured', { isFeatured }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/page/is-featured', {
								_id: id,
								isFeatured
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationIsFeatured', { isFeatured }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle isPinned change
	const handleIsPinned = async (isPinned: boolean) => {
		// Confirmation dialog
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:changeIsPinned', { isPinned }),
			buttons: [
				{
					label: t('button:yes'),
					onClick: async () => {
						setIsLoading(true)

						try {
							const response = await apiProtected.put('admin/page/is-pinned', {
								_id: id,
								isPinned
							})

							if (response.data.status === 'success') {
								// Show success message
								toast.success(t('info:translationIsPinned', { isPinned }), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							} else {
								// Show error message
								toast.error(response.data.message || t('error:unexpectedError'), {
									position: 'bottom-center',
									autoClose: 2000
								})

								setIsLoading(false)
							}
						} catch (error) {
							setIsLoading(false)
							// Show error message
							toast.error(apiErrorHandler(error), {
								position: 'bottom-center',
								autoClose: 2000
							})
						}
					},
					className: 'button--warning'
				},
				{
					label: t('button:cancel'),
					onClick: () => {}
				}
			]
		})
	}

	// Handle delete click
	const handleDelete = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deletePage'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => deleteContent(),
					className: 'button--danger'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	// Delete post
	const deleteContent = async () => {
		try {
			const response = await apiProtected.delete(`admin/page/${id}`)

			if (response?.data?.status === 'success') {
				// Redirect to post list
				navigate('/page')
			} else {
				// Show error message
				toast.error(response.data.message || t('error:unexpectedError'), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		} catch (error) {
			// Show error message
			toast.error(apiErrorHandler(error) || t('error:unexpectedError'), {
				position: 'bottom-center',
				autoClose: 2000
			})
		}
	}

	// Loading
	if (isLoading)
		return (
			<div className="EditPageSettings">
				<Loading />
			</div>
		)

	return (
		<main className="EditPageSettings">
			<h1 className="Admin_H1">{t('admin:headerEditPage')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${content?.lang}/content/${content?.slug}`} />

			<AdminPageNavigation id={id} lang={content?.lang} />

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<div className="EditPageSettings_Settings">
				<FormFieldSet className="EditPageSettings_BulkOperations" legend={t('admin:bulkHeader')}>
					<p>
						<i>{t('admin:bulkDesc')}</i>
					</p>
					<p>{t('admin:pageTagAndMenuOrder')}</p>
					<FormGroup twoColumn={true}>
						<Input
							inputSize="sm"
							type="text"
							placeholder={t('form:pageTag.placeholder')}
							label={t('form:pageTag.label')}
							defaultValue={pageTag}
						/>
						<Input
							inputSize="sm"
							type="number"
							placeholder={t('form:menuOrder.placeholder')}
							label={t('form:menuOrder.label')}
							defaultValue={menuOrder}
						/>
						<Button text={t('button:save')} color="success" disabled={isLoading} onClick={() => handleStatus('published')} />
					</FormGroup>
					<p>{t('admin:showInMainMenu')}</p>
					<FormGroup threeColumn={true}>
						<Button text={t('button:on')} color="success" disabled={isLoading} onClick={() => handleIsFeatured(true)} />

						<Button text={t('button:off')} color="light" disabled={isLoading} onClick={() => handleIsFeatured(false)} />
					</FormGroup>
					<p>{t('admin:showInFooterMenu')}</p>
					<FormGroup threeColumn={true}>
						<Button text={t('button:on')} color="success" disabled={isLoading} onClick={() => handleIsPinned(true)} />
						<Button text={t('button:off')} color="light" disabled={isLoading} onClick={() => handleIsPinned(false)} />
					</FormGroup>
				</FormFieldSet>
				<FormFieldSet className="EditPageSettings_DangerZone" legend={t('admin:dangerZone')}>
					<p>{t('admin:deleteAllPageDesc')}</p>
					<Button text={t('button:deleteAll')} color="error" disabled={isLoading} onClick={handleDelete} />
				</FormFieldSet>
			</div>
		</main>
	)
}

export default Admin(EditPageSettings)
