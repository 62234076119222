// Molecules
import TabLinkNavigation from '../tabLinkNavigation/TabLinkNavigation'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

// Localization
import { useTranslation } from 'react-i18next'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

interface AdminPageNavigationProps {
	id?: string
	lang?: string
}

const AdminPageNavigation = (props: AdminPageNavigationProps) => {
	const { id, lang } = props

	// Translation Hook
	const { t } = useTranslation()

	// Check if language
	const isDefaultLanguage = languageSettings.defaultLanguage === lang

	return (
		<TabLinkNavigation>
			<ReactLink href={`/page/edit/${id}`}>{t('admin:page')}</ReactLink>
			{isDefaultLanguage && <ReactLink href={`/page/edit/${id}/translations`}>{t('admin:translations')}</ReactLink>}
			{isDefaultLanguage && <ReactLink href={`/page/edit/${id}/settings`}>{t('admin:settings')}</ReactLink>}
		</TabLinkNavigation>
	)
}

export default AdminPageNavigation
