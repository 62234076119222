// Molecules
import TabLinkNavigation from '../tabLinkNavigation/TabLinkNavigation'
import ReactLink from '../../../ruya-shared/shared/ui/molecules/reactLink/ReactLink'

// Localization
import { useTranslation } from 'react-i18next'

// Shared
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

interface TheoryNavigationProps {
	id?: string
	lang?: string
}

const TheoryNavigation = (props: TheoryNavigationProps) => {
	const { id, lang } = props

	// Translation Hook
	const { t } = useTranslation()

	// Check if language
	const isDefaultLanguage = languageSettings.defaultLanguage === lang

	return (
		<TabLinkNavigation>
			<ReactLink href={`/theories/edit/${id}`}>{t('admin:theory')}</ReactLink>
			{isDefaultLanguage && <ReactLink href={`/theories/edit/${id}/translations`}>{t('admin:translations')}</ReactLink>}
		</TabLinkNavigation>
	)
}

export default TheoryNavigation
