import { useRef, useState, useEffect, type ChangeEvent, type SelectHTMLAttributes, forwardRef } from 'react'
import './Select.scss'

// Atoms
import MaterialSymbol from '../materialSymbol/MaterialSymbol'

// Helpers
import { nanoid } from 'nanoid'

interface Option {
	[key: string]: any
	label?: string
	value?: any
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	id?: string
	icon?: string
	inputSize?: string
	label?: string
	options?: Option[] | string[] | any
	keyField?: string
	valueField?: string
	labelField?: string
	className?: string
	disabled?: boolean
	placeholder?: string
	enableClear?: boolean
	onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
	invalid?: boolean
	invalidMessage?: string
	valid?: boolean
	warning?: boolean
	[key: string]: any // Additional props that might be passed
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
	// Destructuring props
	const {
		id = nanoid(),
		icon,
		inputSize,
		label,
		options,
		keyField = null,
		valueField = null,
		labelField = null,
		className,
		disabled,
		placeholder,
		enableClear,
		onChange,
		invalid,
		invalidMessage,
		valid,
		warning,
		...rest
	} = props

	// const selectRef = useRef<HTMLSelectElement>(null)
	// ref = selectRef
	const [showClearIcon, setShowClearIcon] = useState(false)
	const [isPlaceholderSelected, setIsPlaceholderSelected] = useState(false)

	// Update clear icon toggle state
	useEffect(() => {
		const currentSelect = ref as React.RefObject<HTMLSelectElement>
		const doNotShow = [undefined, '']
		const value = Array.isArray(rest.value) ? rest.value.join(', ') : (rest.value || '').toString()

		setShowClearIcon(!doNotShow.includes(currentSelect?.current?.value || value))

		// Set placeholder
		setIsPlaceholderSelected(currentSelect?.current?.value === '')
	}, [ref, rest.value])

	// Handle value reset
	const handleResetSelect = () => {
		const currentSelect = ref as React.RefObject<HTMLSelectElement>
		if (currentSelect?.current) {
			currentSelect.current.value = ''
			currentSelect.current.dispatchEvent(new Event('change', { bubbles: true }))
		}
	}

	// Handle value change
	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		setShowClearIcon(e.target.value !== '')
		onChange?.(e)
	}

	// Classes
	const classNames =
		'Select' +
		`${className !== undefined ? ' ' + className : ''}` +
		`${inputSize ? ' Select--' + inputSize : ''}` +
		`${disabled ? ' Select--disabled' : ''}` +
		`${label ? ' Select--hasLabel' : ''}` +
		`${valid ? ' Select--valid' : ''}` +
		`${warning ? ' Select--warning' : ''}` +
		`${invalid ? ' Select--invalid' : ''}` +
		`${isPlaceholderSelected ? ' Select--placeholderSelected' : ''}`

	// Extract value from option
	const getValue = (option: Option) => {
		if (valueField) return option?.[valueField]
		return option?.value || option
	}

	// Extract value from option
	const getLabel = (option: Option) => {
		if (labelField) return option?.[labelField]
		return option?.label || option
	}

	return (
		<div className={classNames} {...(icon !== undefined ? { icon } : {})}>
			{![undefined, false].includes(enableClear) && showClearIcon && (
				<MaterialSymbol onClick={handleResetSelect} name="close" className="Select_ResetIcon" />
			)}
			{label && <label htmlFor={id}>{label}</label>}

			<select
				{...rest}
				id={id}
				ref={ref}
				disabled={disabled}
				{...(placeholder !== undefined ? (rest.value !== undefined ? {} : { defaultValue: '' }) : {})}
				onChange={handleChange}>
				{placeholder && (
					<option value="" disabled hidden>
						{placeholder}
					</option>
				)}
				{Array.isArray(options) &&
					options?.length > 0 &&
					options.map((option, i) => {
						if (typeof option !== 'string') {
							return (
								<option key={keyField ? option[keyField] : i} value={getValue(option)}>
									{getLabel(option)}
								</option>
							)
						}
						return (
							<option key={i} value={option}>
								{option}
							</option>
						)
					})}
				{rest.children}
			</select>

			{invalid && invalidMessage && <p className="Select_InvalidMessage"><MaterialSymbol name="cancel" fill="1" /> {invalidMessage}</p>}
		</div>
	)
})

Select.displayName = 'Select'
export default Select
