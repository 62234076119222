import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

// Styles
import './assets/admin.scss'
import './ruya-shared/shared/assets/globals.scss'

// Router
import AppRouter from './routes/AppRouter'

// Time
import { Settings } from 'luxon'

// Initialise localization
import './i18n'

// React Toastify
import { ToastContainer } from 'react-toastify'

// Config
import { commonSettings } from './ruya-shared/shared/config/commonSettings'

// Utils
import FontLoader from './ruya-shared/shared/utils/FontLoader'

// Set default timezone
Settings.defaultZone = commonSettings.defaultTimeZoneName

// Root container
const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
	<StrictMode>
		<FontLoader />
		<AppRouter />
		<ToastContainer />
	</StrictMode>
)
