import { useEffect, useRef } from 'react'
import './Login.scss'

// Cloudflare Turnstile
import { Turnstile, type TurnstileInstance } from '@marsidev/react-turnstile'

// Router
import { useLocation, useNavigate } from 'react-router-dom'

// Localization
import { useTranslation } from 'react-i18next'

// Zustand
import useUserStore from '../../../store/userStore'
import useLanguageStore from '../../../store/languageStore'

// React Hook Form
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { loginSchema } from '../../../validation/loginSchema'

// Atoms
import { Form, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Template
import Public from '../../templates/public/Public'

// Utilities
import { getSupportedTurnstileLanguage } from '../../../ruya-shared/shared/utils/commonHelper'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'

type LoginFormValues = {
	email: string
	password: string
	captchaToken: string
}

const Login = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Turnstile
	const captchaTokenRef = useRef<TurnstileInstance>(null)

	// Zustand
	const userStore = useUserStore()
	const languageStore = useLanguageStore()

	// Router
	const navigate = useNavigate()
	const { state } = useLocation()

	// Check authentication
	useEffect(() => {
		if (userStore.user) {
			// If user requested a protected route before login, redirect to that route
			if (state?.from) navigate(state.from)

			// Else redirect to dashboard
			navigate(commonSettings.apps.admin.paths.dashboard)
		} else {
			userStore.authenticateUserWithToken()
		}
	}, [userStore.user])

	// React Hook Form
	const formOptions = { mode: 'onChange', resolver: yupResolver<LoginFormValues>(loginSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		formState: { errors, isValid }
	} = useForm<LoginFormValues>(formOptions)

	// Form submit
	const onSubmit = async (values: LoginFormValues) => {
		// Login user
		const response = await userStore.authenticateUser(values)

		if (!response) {
			// Reset captcha
			captchaTokenRef.current?.reset()
		}
	}

	return (
		<main className="Login">
			<Form className="Login_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<h1>{t('login:header')}</h1>

				<FormGroup>
					<Input
						icon="email"
						inputSize="lg"
						type="text"
						placeholder={t('form:email.placeholder')}
						label={t('form:email.label')}
						autoComplete="email"
						invalid={Boolean(errors?.email?.message)}
						invalidMessage={errors?.email?.message}
						{...register('email')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						icon="password"
						inputSize="lg"
						type="password"
						placeholder={t('form:password.placeholder')}
						label={t('form:password.label')}
						autoComplete="current-password"
						invalid={Boolean(errors?.password?.message)}
						invalidMessage={errors?.password?.message}
						{...register('password')}
					/>
				</FormGroup>

				{userStore.error && (
					<FormGroup>
						<p className="Login_Error">{userStore.error}</p>
					</FormGroup>
				)}

				<FormGroup>
					<Controller
						control={control}
						name="captchaToken"
						render={({ field }) => {
							const { onChange } = field
							return (
								<Turnstile
									ref={captchaTokenRef}
									siteKey={commonSettings.cloudFlare.turnstile.turnstileSiteKey}
									scriptOptions={{ async: true, defer: true }}
									options={{
										tabIndex: 3,
										language: getSupportedTurnstileLanguage(languageStore.selectedLanguage?.isoCode)
									}}
									onError={() => captchaTokenRef.current?.reset()}
									onSuccess={token => onChange(token)}
								/>
							)
						}}
					/>
				</FormGroup>

				<FormGroup>
					<Button
						text={t('button:login')}
						type="submit"
						size="md"
						disabled={userStore.loading || !isValid}
						loading={userStore.loading}
						loadingText={t('button:loading')}
						block
					/>
				</FormGroup>
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Public(Login)
