import { useCallback, useEffect, useState } from 'react'
import './EditArticle.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'
import { articleUpdateSchema } from '../../../validation/articleSchema'

// Atoms
import { Form, FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import ContentBox from '../../../ruya-shared/shared/ui/atoms/contentBox/ContentBox'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import ArticleEditor from '../../molecules/articleEditor/ArticleEditor'
import AdminArticleNavigation from '../../molecules/adminArticleNavigation/AdminArticleNavigation'
import { GenerateArticleModal } from '../../molecules/generateArticle/GenerateArticle'

// Zustand
import useArticleStore from '../../../store/articleStore'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Toastify
import { toast } from 'react-toastify'

// Shared
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { serializeFieldErrors } from '../../../ruya-shared/shared/utils/formHelper'
import type { IArticle, IArticleTag, IArticleReference } from '../../../ruya-shared/shared/types'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditArticle = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const articleStore = useArticleStore()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [post, setPost] = useState<IArticle | null>(null)
	const [isGenerateArticleModalOpen, setGenerateArticleModalOpen] = useState<boolean>(false)

	// Get language object
	const languageObject = getActiveLanguages.find(lang => lang.isoCode === post?.lang)

	// Is default language
	const isDefaultLanguage = post?.lang === languageSettings.defaultLanguage

	// Input direction (ltr or rtl) depending on the language
	const dir = languageObject?.direction || 'ltr'

	// Is post has translations
	const isPostHasTranslations: boolean = (post?.languageRelations?.length || 0) > 0

	// Get Post Tags
	useEffect(() => {
		articleStore.getArticleTags(languageSettings.defaultLanguage)
	}, [])

	// React Hook Form
	const initialTag: IArticleTag = {
		name: '',
		slug: ''
	}
	const initialReference: IArticleReference = {
		author: '',
		year: '',
		title: '',
		publisherOrJournal: '',
		volume: '',
		issue: '',
		link: ''
	}
	const formOptions = { mode: 'onChange', resolver: yupResolver(articleUpdateSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		getValues,
		reset,
		setValue,
		formState: { errors, isDirty }
	} = useForm<IArticle>(formOptions)

	// Get Post by ID with api call
	useEffect(() => {
		if (!id) navigate('/article')
		getPost()
	}, [id])

	// Reset form
	const resetForm = () => {
		for (let i = tags.fields.length - 1; i >= 0; i--) tags.remove(i)
		for (let i = references.fields.length - 1; i >= 0; i--) references.remove(i)
		setValue('content', '<p>-</p>')
		reset()
	}

	// Handle generated post import
	const handlePostImport = useCallback(
		(generatedPost: IArticle) => {
			if (generatedPost) {
				// Reset form
				resetForm()

				// Merge state, overwrite existing fields
				const copyOfPost = { ...post } as IArticle
				// Reset arrays
				copyOfPost.tags = []
				copyOfPost.references = []

				const mergedData = { ...copyOfPost, ...generatedPost } as IArticle

				Object.keys(mergedData).forEach(key => {
					const formKey = key as keyof IArticle

					// Explicitly declare the type of the element at mergedData[formKey]
					const element = mergedData[formKey]

					// Use a type guard to ensure the element is an array
					if (Array.isArray(element)) {
						element.forEach((item: any, index: number) => {
							setTimeout(() => {
								if (formKey === 'tags') tags.append(item)
								if (formKey === 'references') references.append(item)
							}, index * 20) // delay of 20ms for each item
						})
					} else {
						// Handle regular fields
						setValue(formKey, element)
					}
				})

				setGenerateArticleModalOpen(false)
			}
		},
		[setValue, resetForm]
	)

	// Get Post by ID
	const getPost = async () => {
		const response = await apiProtected.get(`admin/article/${id}`)

		if (response.data.status === 'success') {
			const articleData = response.data.data as IArticle

			// Update state
			setPost(articleData)

			// Set default values
			Object.keys(articleData).forEach(key => {
				const formKey = key as keyof IArticle
				setValue(formKey, articleData[formKey])
			})
		} else {
			navigate('/article')
		}
	}

	// Tag field arrays
	const tags = useFieldArray({ name: 'tags', control }) // Tags array
	const watchTagsFieldArray = watch('tags') // Watch tag changes
	const controlledTagFields = tags.fields.map((field, index) => {
		// Check if watchTagsFieldArray at the current index is defined
		if (watchTagsFieldArray?.[index]) {
			return { ...field, ...watchTagsFieldArray[index] }
		}
		return field
	})

	// Reference field arrays
	const references = useFieldArray({ name: 'references', control }) // References array
	const watchReferencesFieldArray = watch('references') // Watch reference changes
	const controlledReferenceFields = references.fields.map((field, index) => {
		// Check if watchTagsFieldArray at the current index is defined
		if (watchReferencesFieldArray?.[index]) {
			return { ...field, ...watchReferencesFieldArray[index] }
		}
		return field
	})

	// Watch
	const slugValue = watch('slug')
	const langValue = watch('lang')

	const addNewTag = () => {
		tags.append(initialTag)
	}

	const removeTag = (index: number) => {
		tags.remove(index)
	}

	const addNewReference = () => {
		references.append(initialReference)
	}

	const removeReference = (index: number) => {
		references.remove(index)
	}

	// Form submit
	const onSubmit = async (values: IArticle) => {
		// Set loading
		setIsLoading(true)

		// Clear API error
		setApiError(null)

		// Update post
		const response = await articleStore.updateArticle(values)

		if (response.status === 'success' && response.data) {
			const articleData = response.data as unknown as IArticle

			// Show success message
			toast.success('Post updated!', {
				position: 'bottom-center',
				autoClose: 2000
			})

			// Update state
			setPost(articleData)

			// Set default values
			Object.keys(articleData).forEach(key => {
				const formKey = key as keyof IArticle
				setValue(formKey, articleData[formKey])
			})
		} else {
			setApiError(response.message || t('error:unexpectedError'))
		}
		setIsLoading(false)
	}

	// Delete article translation
	const deleteArticleTranslation = async () => {
		// Confirmation dialog
		if (window.confirm(t('dialog:deleteArticleTranslation'))) {
			setIsLoading(true)

			try {
				const response = await apiProtected.delete(`admin/article/${id}`)

				if (response.data.status === 'success') {
					// Show success message
					toast.success(t('info:articleTranslationDeleted'), {
						position: 'bottom-center',
						autoClose: 2000
					})

					// Redirect
					navigate('/article')
				} else {
					// Show error message
					toast.error(response.data.message || t('error:unexpectedError'), {
						position: 'bottom-center',
						autoClose: 2000
					})

					setIsLoading(false)
				}
			} catch (error) {
				setIsLoading(false)
				// Show error message
				toast.error(apiErrorHandler(error), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		}
	}

	return (
		<main className="EditArticle">
			<h1 className="Admin_H1">{t('admin:headerEditArticle')}</h1>

			{isDefaultLanguage && !isPostHasTranslations && (
				<div className="AddArticle_TopBar">
					<Button
						text={t('button:reGenerateArticle')}
						icon="article_shortcut"
						color="warning"
						type="button"
						onClick={() => setGenerateArticleModalOpen(true)}
					/>
					{isGenerateArticleModalOpen && (
						<GenerateArticleModal
							isOpen={isGenerateArticleModalOpen}
							onClose={() => setGenerateArticleModalOpen(false)}
							onResponse={handlePostImport}
						/>
					)}
				</div>
			)}

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${langValue}/articles/${slugValue}`} />

			<AdminArticleNavigation id={id} lang={post?.lang} />

			<Form className="EditArticle_Form" noValidate>
				<input type="hidden" {...register('_id')} />
				<input type="hidden" {...register('authorId')} />
				<input type="hidden" {...register('status')} />
				<input type="hidden" {...register('audio')} />

				<FormGroup className="EditArticle_FeatureAndSlug">
					<Switch label={t('form:isFeatured.label')} defaultValue={watch('isFeatured')} {...register('isFeatured')} />

					<Switch label={t('form:isPinned.label')} defaultValue={watch('isPinned')} {...register('isPinned')} />

					<Input
						inputSize="sm"
						dir={dir}
						icon="link"
						type="text"
						placeholder={t('form:slug.placeholder')}
						label={t('form:slug.label')}
						invalid={Boolean(errors?.slug?.message)}
						invalidMessage={errors?.slug?.message?.toString()}
						{...register('slug')}
					/>
				</FormGroup>

				<FormGroup threeColumn={true}>
					<Input
						inputSize="sm"
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.lang?.message)}
						invalidMessage={errors?.lang?.message?.toString()}
						disabled={true}
						{...register('lang')}
					/>

					<Select
						inputSize="sm"
						dir={dir}
						label={t('form:postStatus.label')}
						options={[
							{ label: 'Draft', value: 'draft' },
							{ label: 'Published', value: 'published' },
							{ label: 'Archived', value: 'archived' },
							{ label: 'Deleted', value: 'deleted' }
						]}
						value={watch('status')}
						{...register('status')}
					/>

					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						label={t('form:readingTime.label')}
						invalid={Boolean(errors?.readingTime?.message)}
						invalidMessage={errors?.readingTime?.message?.toString()}
						{...register('readingTime')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.title?.message)}
						invalidMessage={errors?.title?.message?.toString()}
						{...register('title')}
					/>
				</FormGroup>

				<FormGroup>
					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						placeholder={t('form:description.placeholder')}
						label={t('form:description.label')}
						invalid={Boolean(errors?.description?.message)}
						invalidMessage={errors?.description?.message?.toString()}
						{...register('description')}
					/>
				</FormGroup>

				<FormGroup>
					<ArticleEditor defaultFolder={`content/${id}`} dir={dir} value={watch('content')} {...register('content')} />
				</FormGroup>

				<FormGroup>
					<ContentBox title={t('admin:tags')} isCollapsible={true} defaultCollapsed={true}>
						{controlledTagFields.map((tag: IArticleTag, i: number) => (
							<FormGroup threeColumn={true} key={`tags${i}`} className="FormGroup_Tags">
								<Input
									dir={dir}
									inputSize="sm"
									placeholder={t('form:tagName.placeholder')}
									label={t('form:tagName.label')}
									invalid={Boolean(errors?.tags?.[i]?.name?.message)}
									invalidMessage={errors?.tags?.[i]?.name?.message?.toString()}
									{...register(`tags.${i}.name`)}
								/>

								<Input
									dir={dir}
									inputSize="sm"
									placeholder={t('form:tagSlug.placeholder')}
									label={t('form:tagSlug.label')}
									invalid={Boolean(errors?.tags?.[i]?.slug?.message)}
									invalidMessage={errors?.tags?.[i]?.slug?.message?.toString()}
									{...register(`tags.${i}.slug`)}
								/>

								<Button
									text={t('button:removeTag')}
									color="warning"
									icon="delete"
									type="button"
									onClick={() => removeTag(i)}
								/>
							</FormGroup>
						))}

						<Button text={t('button:addNewTag')} color="success" icon="add" type="button" onClick={addNewTag} />
					</ContentBox>
				</FormGroup>

				<FormGroup>
					<ContentBox title={t('admin:references')} isCollapsible={true} defaultCollapsed={true}>
						{controlledReferenceFields.map((reference: IArticleReference, i: number) => (
							<FormGroup fourColumn={true} key={`references${i}`} className="FormGroup_References">
								<Input
									inputSize="sm"
									placeholder={t('form:referenceTitle.placeholder')}
									label={t('form:referenceTitle.label')}
									invalid={Boolean(errors?.references?.[i]?.title?.message)}
									invalidMessage={errors?.references?.[i]?.title?.message?.toString()}
									{...register(`references.${i}.title`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referenceLink.placeholder')}
									label={t('form:referenceLink.label')}
									invalid={Boolean(errors?.references?.[i]?.link?.message)}
									invalidMessage={errors?.references?.[i]?.link?.message?.toString()}
									{...register(`references.${i}.link`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referenceAuthor.placeholder')}
									label={t('form:referenceAuthor.label')}
									{...register(`references.${i}.author`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referenceYear.placeholder')}
									label={t('form:referenceYear.label')}
									{...register(`references.${i}.year`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referencePublisherOrJournal.placeholder')}
									label={t('form:referencePublisherOrJournal.label')}
									{...register(`references.${i}.publisherOrJournal`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referenceVolume.placeholder')}
									label={t('form:referenceVolume.label')}
									{...register(`references.${i}.volume`)}
								/>

								<Input
									inputSize="sm"
									placeholder={t('form:referenceIssue.placeholder')}
									label={t('form:referenceIssue.label')}
									{...register(`references.${i}.issue`)}
								/>

								<Button
									text={t('button:removeReference')}
									color="warning"
									icon="delete"
									type="button"
									onClick={() => removeReference(i)}
								/>
							</FormGroup>
						))}

						<Button text={t('button:addNewReference')} color="success" icon="add" type="button" onClick={addNewReference} />
					</ContentBox>
				</FormGroup>

				{apiError && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					{errors && Object.keys(errors).length > 0 && <InfoBox icon="true" type="error" text={serializeFieldErrors(errors)} />}

					<Button
						text={t('button:save')}
						color="success"
						loading={isLoading}
						type="button"
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
					/>
				</FormGroup>

				{!isDefaultLanguage && (
					<FormGroup>
						<FormFieldSet className="EditArticleSettings_DangerZone" legend={t('admin:dangerZone')}>
							<p>{t('admin:deleteArticleTranslation')}</p>
							<Button
								text={t('button:deleteArticleTranslation')}
								color="error"
								disabled={isLoading}
								onClick={() => deleteArticleTranslation()}
							/>
						</FormFieldSet>
					</FormGroup>
				)}
			</Form>

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(EditArticle)
