// Configuration
const appSettings: AppSettings = {
	ai: {
		audioModels: [
			{
				company: 'OpenAI',
				modelName: 'tts-1-hd',
				isDefault: true
			},
			{
				company: 'OpenAI',
				modelName: 'tts-1',
				isDefault: false
			}
		],
		audioVoices: [
			{
				label: 'Alloy',
				voice: 'alloy',
				isDefault: false
			},
			{
				label: 'Echo',
				voice: 'echo',
				isDefault: false
			},
			{
				label: 'Fable',
				voice: 'fable',
				isDefault: false
			},
			{
				label: 'Onyx',
				voice: 'onyx',
				isDefault: false
			},
			{
				label: 'Nova',
				voice: 'nova',
				isDefault: true
			},
			{
				label: 'Shimmer',
				voice: 'shimmer',
				isDefault: false
			}
		]
	},
	admin: {
		post: {
			imageSizes: [
				{ name: 'cover', displayName: 'Article cover image', width: 800, height: 440 },
				{ name: 'list', displayName: 'Article listing image', width: 384, height: 216 },
				{ name: 'thumbnail', displayName: 'Article thumbnail image', width: 64, height: 64 }
				// { name: 'pinterest', displayName: 'Pinterest', width: 1000, height: 1500 },
				// { name: 'facebook', displayName: 'Facebook', width: 1200, height: 628 }
			]
		}
	},
	maskedInput: {
		genericMoneyFormat: {
			thousandSeparator: ',',
			thousandsGroupStyle: 'thousand',
			fixedDecimalScale: true,
			decimalScale: 2,
			decimalSeparator: '.'
		},
		dollarFormat: {
			thousandSeparator: ',',
			thousandsGroupStyle: 'thousand',
			fixedDecimalScale: true,
			decimalScale: 2,
			decimalSeparator: '.',
			prefix: '$'
		},
		millisecondFormat: {
			valueIsNumericString: true,
			suffix: ' ms'
		},
		genericNumberFormat: {
			thousandSeparator: ',',
			thousandsGroupStyle: 'thousand'
		},
		percentageFormat: {
			thousandSeparator: ',',
			thousandsGroupStyle: 'thousand',
			decimalSeparator: '.',
			suffix: '%'
		}
	}
}

export default appSettings
