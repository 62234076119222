import * as Yup from 'yup'

// Localisation
import i18n from '../i18n'

// Regex
import { urlRegEx, urlSlugRegEx } from '../ruya-shared/shared/validation/regularExpressions'

// Utils
import { Debouncer } from '../ruya-shared/shared/utils/commonHelper'

// API
import apiProtected from '../api/apiProtected'

// Types
import type { GenerateArticleFormValues } from '../@types/generateArticle'
import type { IArticle } from '../ruya-shared/shared/types'


// New Post Validation Schema
export const articleSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IArticle>> = Yup.object({
	lang: Yup.string().required(i18n.t('form:language.required')),
	slug: Yup.string()
		.required(i18n.t('form:slug.required'))
		.min(3, i18n.t('form:slug.min'))
		.max(70, i18n.t('form:slug.max'))
		.matches(urlSlugRegEx, i18n.t('form:slug.format'))
		.test('is-unique', i18n.t('form:slug.unique'), function (value) {
			// Don't run the test if the value is less than 3 characters
			if (!value || value.length < 3) return true

			// Get the language value
			const lang = this.resolve(Yup.ref('lang')) as string

			return new Promise((resolve, reject) => {
				slugDebouncer.debounce(() => slugUniqueCheck(lang, value, resolve, reject), 500)
			})
		}),
	title: Yup.string().required(i18n.t('form:title.required')),
	description: Yup.string().required(i18n.t('form:description.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	isFeatured: Yup.boolean().required(i18n.t('form:isFeatured.required')),
	isPinned: Yup.boolean().required(i18n.t('form:isPinned.required')),
	readingTime: Yup.string().required(i18n.t('form:readingTime.required')),
	images: Yup.object()
		.shape({
			cover: Yup.string().nullable().notRequired(),
			list: Yup.string().nullable().notRequired(),
			thumbnail: Yup.string().nullable().notRequired()
		})
		.nullable()
		.notRequired(),
	audio: Yup.string().nullable().notRequired(),
	video: Yup.string().nullable().notRequired(),
	useVideoAsCover: Yup.boolean().nullable().notRequired(),
	status: Yup.string()
		.oneOf(['draft', 'published', 'archived', 'deleted'], i18n.t('form:status.invalid'))
		.required(i18n.t('form:status.required')),
	authorId: Yup.string().required(i18n.t('form:authorId.required')),
	tags: Yup.array().of(
		Yup.object({
			name: Yup.string().required(i18n.t('form:tagName.required')),
			slug: Yup.string().required(i18n.t('form:tagSlug.required')).matches(urlSlugRegEx, i18n.t('form:tagSlug.format'))
		})
	),
	references: Yup.array().of(
		Yup.object({
			title: Yup.string().required(i18n.t('form:referenceTitle.required')),
			link: Yup.string()
				.notRequired()
				.nullable()
				.test('is-valid-url', i18n.t('form:referenceLink.format'), value => {
					if (!value) return true
					return urlRegEx.test(value)
				}),
			author: Yup.string().notRequired(),
			year: Yup.string().notRequired(),
			publisherOrJournal: Yup.string().notRequired(),
			volume: Yup.string().notRequired(),
			issue: Yup.string().notRequired()
		})
	)
}).required()

// Edit Post Validation Schema
export const articleUpdateSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IArticle>> = Yup.object({
	lang: Yup.string().required(i18n.t('form:language.required')),
	slug: Yup.string()
		.required(i18n.t('form:slug.required'))
		.min(3, i18n.t('form:slug.min'))
		.max(70, i18n.t('form:slug.max'))
		.matches(urlSlugRegEx, i18n.t('form:slug.format'))
		.test('is-unique', i18n.t('form:slug.unique'), function (value) {
			// Don't run the test if the value is less than 3 characters
			if (!value || value.length < 3) return true

			// Get the language value
			const lang = this.resolve(Yup.ref('lang')) as string
			const id = this.resolve(Yup.ref('_id')) as string

			return new Promise((resolve, reject) => {
				slugDebouncer.debounce(() => slugUniqueCheck(lang, value, resolve, reject, id), 500)
			})
		}),
	title: Yup.string().required(i18n.t('form:title.required')),
	description: Yup.string().required(i18n.t('form:description.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	isFeatured: Yup.boolean().required(i18n.t('form:isFeatured.required')),
	isPinned: Yup.boolean().required(i18n.t('form:isPinned.required')),
	readingTime: Yup.string().required(i18n.t('form:readingTime.required')),
	images: Yup.object()
		.shape({
			cover: Yup.string().notRequired(),
			list: Yup.string().nullable().notRequired(),
			thumbnail: Yup.string().nullable().notRequired()
		})
		.nullable()
		.notRequired(),
	audio: Yup.string().nullable().notRequired(),
	video: Yup.string().nullable().notRequired(),
	useVideoAsCover: Yup.boolean().nullable().notRequired(),
	status: Yup.string()
		.oneOf(['draft', 'published', 'archived', 'deleted'], i18n.t('form:status.invalid'))
		.required(i18n.t('form:status.required')),
	authorId: Yup.string().required(i18n.t('form:authorId.required')),
	tags: Yup.array().of(
		Yup.object({
			name: Yup.string().required(i18n.t('form:tagName.required')),
			slug: Yup.string().required(i18n.t('form:tagSlug.required')).matches(urlSlugRegEx, i18n.t('form:tagSlug.format'))
		})
	),
	references: Yup.array().of(
		Yup.object({
			title: Yup.string().required(i18n.t('form:referenceTitle.required')),
			link: Yup.string()
				.notRequired()
				.nullable()
				.test('is-valid-url', i18n.t('form:referenceLink.format'), value => {
					if (!value) return true
					return urlRegEx.test(value)
				}),
			author: Yup.string().notRequired(),
			year: Yup.string().notRequired(),
			publisherOrJournal: Yup.string().notRequired(),
			volume: Yup.string().notRequired(),
			issue: Yup.string().notRequired()
		})
	)
}).required()

// Post Generation Form Values
export const generateArticleSchema: Yup.ObjectSchema<GenerateArticleFormValues> = Yup.object({
	template: Yup.string().required(i18n.t('form:templatePrompt.required')),
	prompt: Yup.string().required(i18n.t('form:articlePrompt.required'))
}).required()

const slugDebouncer = new Debouncer()

const slugUniqueCheck = (
	lang: string,
	value: string,
	resolve: (value?: any) => void,
	reject: (reason?: any) => void,
	id?: string
): void => {
	// API endpoint
	let apiEndpoint = `/admin/article/verify-slug/${lang}/${value}`

	// If id is provided, add it to the endpoint
	if (id) apiEndpoint += `/${id}`

	apiProtected
		.get(apiEndpoint)
		.then((response: { data: boolean }) => {
			const isUnique = response.data as boolean
			if (isUnique) {
				resolve(true)
			} else {
				resolve(new Yup.ValidationError(i18n.t('form:slug.unique'), value, 'slug'))
			}
		})
		.catch((error: any) => resolve(new Yup.ValidationError(i18n.t('form:slug.apiError'), value, 'slug')))
}
