import './NavigationLink.scss'

// Router
import { NavLink } from 'react-router-dom'

interface NavigationLinkProps {
	to: string
	label: string
}

const NavigationLink = (props: NavigationLinkProps) => {
	// Props
	const { to, label } = props

	// Class name
	const className = 'NavigationLink'

	return (
		<NavLink
			end={false}
			to={to}
			className={({ isActive, isPending }) =>
				isPending ? className + ' NavigationLink--pending' : isActive ? className + ' NavigationLink--active' : className
			}>
			{label}
		</NavLink>
	)
}

export default NavigationLink
