import * as Yup from 'yup'

// Localisation
import i18n from '../i18n'

// Regex
import { urlSlugRegEx } from '../ruya-shared/shared/validation/regularExpressions'

// Helper
import { Debouncer } from '../ruya-shared/shared/utils/commonHelper'

// API
import apiProtected from '../api/apiProtected'

// Types
import type { IPage } from '../ruya-shared/shared/types/PageTypes'


// New Content Validation Schema
export const pageSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IPage>> = Yup.object({
	_id: Yup.string().nullable().notRequired(),
	createdAt: Yup.date().nullable().notRequired(),
	updatedAt: Yup.date().nullable().notRequired(),
	lang: Yup.string().required(i18n.t('form:language.required')),
	slug: Yup.string()
		.required(i18n.t('form:slug.required'))
		.min(3, i18n.t('form:slug.min'))
		.max(70, i18n.t('form:slug.max'))
		.matches(urlSlugRegEx, i18n.t('form:slug.format'))
		.test('is-unique', i18n.t('form:slug.unique'), function (value) {
			// Don't run the test if the value is less than 3 characters
			if (!value || value.length < 3) return true

			// Get the language value
			const lang = this.resolve(Yup.ref('lang')) as string

			return new Promise((resolve, reject) => {
				slugDebouncer.debounce(() => slugUniqueCheck(lang, value, resolve, reject), 500)
			})
		}),
	linkLabel: Yup.string().required(i18n.t('form:linkLabel.required')),
	title: Yup.string().required(i18n.t('form:title.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	metaTitle: Yup.string().required(i18n.t('form:metaTitle.required')),
	metaDescription: Yup.string().required(i18n.t('form:metaDescription.required')),
	metaKeywords: Yup.string().required(i18n.t('form:metaKeywords.required')),
	showInMainMenu: Yup.boolean().required(i18n.t('form:showInMainMenu.required')),
	showInFooterMenu: Yup.boolean().required(i18n.t('form:showInFooterMenu.required')),
	menuOrder: Yup.number().required(i18n.t('form:menuOrder.required')),
	pageTag: Yup.string().required(i18n.t('form:pageTag.required')),
	parentId: Yup.string().nullable().notRequired(),
	languageRelations: Yup.array()
		.of(
			Yup.object().shape({
				lang: Yup.string().required(i18n.t('form:languageRelationLang.required')),
				relatedPageId: Yup.string().required(i18n.t('form:languageRelationRelatedPageId.required'))
			})
		)
		.optional()
		.nullable()
}).required()

// Edit Content Validation Schema
export const pageUpdateSchema: Yup.ObjectSchema<Yup.AnyObject, Partial<IPage>> = Yup.object({
	lang: Yup.string().required(i18n.t('form:language.required')),
	slug: Yup.string()
		.required(i18n.t('form:slug.required'))
		.min(3, i18n.t('form:slug.min'))
		.max(70, i18n.t('form:slug.max'))
		.matches(urlSlugRegEx, i18n.t('form:slug.format'))
		.test('is-unique', i18n.t('form:slug.unique'), function (value) {
			// Don't run the test if the value is less than 3 characters
			if (!value || value.length < 3) return true

			// Get the language value
			const lang = this.resolve(Yup.ref('lang')) as string
			const id = this.resolve(Yup.ref('_id')) as string

			return new Promise((resolve, reject) => {
				slugDebouncer.debounce(() => slugUniqueCheck(lang, value, resolve, reject, id), 500)
			})
		}),
	linkLabel: Yup.string().required(i18n.t('form:linkLabel.required')),
	title: Yup.string().required(i18n.t('form:title.required')),
	content: Yup.string().required(i18n.t('form:content.required')),
	metaTitle: Yup.string().required(i18n.t('form:metaTitle.required')),
	metaDescription: Yup.string().required(i18n.t('form:metaDescription.required')),
	metaKeywords: Yup.string().required(i18n.t('form:metaKeywords.required')),
	showInMainMenu: Yup.boolean().required(i18n.t('form:showInMainMenu.required')),
	showInFooterMenu: Yup.boolean().required(i18n.t('form:showInFooterMenu.required')),
	menuOrder: Yup.number().required(i18n.t('form:menuOrder.required')),
	pageTag: Yup.string().required(i18n.t('form:pageTag.required'))
}).required()

const slugDebouncer = new Debouncer()

const slugUniqueCheck = (
	lang: string,
	value: string,
	resolve: (value?: any) => void,
	reject: (reason?: any) => void,
	id?: string
): void => {
	// API endpoint
	let apiEndpoint = `/page/verify-slug/${lang}/${value}`

	// If id is provided, add it to the endpoint
	if (id) apiEndpoint += `/${id}`

	apiProtected
		.get(apiEndpoint)
		.then((response: { data: boolean }) => {
			const isUnique = response.data as boolean
			if (isUnique) {
				resolve(true)
			} else {
				resolve(new Yup.ValidationError(i18n.t('form:slug.unique'), value, 'slug'))
			}
		})
		.catch((error: any) => resolve(new Yup.ValidationError(i18n.t('form:slug.apiError'), value, 'slug')))
}
