import { Children, type ReactNode } from 'react'
import './TabLinkNavigation.scss'

interface TabLinkNavigationProps {
	children: ReactNode
}

const TabLinkNavigation = (props: TabLinkNavigationProps) => {
	const { children } = props

	return (
		<div className="TabLinkNavigation">
			<ul>
				{Children.map(children, child => (
					<li>{child}</li>
				))}
			</ul>
		</div>
	)
}

export default TabLinkNavigation
