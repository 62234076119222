// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Utils
import { errorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Types
import type { InterpreterDataState, InterpreterState } from '../@types/interpreter'

const initialState: InterpreterDataState = {
	interpreterList: [],
	loading: false,
	error: null
}

const useInterpreterStore = create<InterpreterState>((set, get) => ({
	...initialState,
	setLoading: loading => set({ loading }),
	setApiError: error => set({ error }),
	loadInterpreterList: async (language, active) => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { data, status } = await apiProtected.get('/admin/interpreter/' + language + (active === 'all' ? '' : '/' +active))

			// Check axios error
			if (status !== 200) set({ error: 'Error fetching interpreter list', loading: false })

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ interpreterList: data.data, loading: false })
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	},
	addInterpreter: async interpreterData => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { data } = await apiProtected.post('/admin/interpreter', interpreterData)

			// Check API error
			if (data.status !== 'success') {
				set({ error: data.message, loading: false })
				return false
			}

			set({ loading: false })

			return true
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
			return false
		}
	},
	updateInterpreter: async interpreterData => {
		// Set loading
		set({ loading: true, error: null })

		try {
			const { data } = await apiProtected.put('/admin/interpreter', interpreterData)

			// Check API error
			if (data.status !== 'success') set({ error: data.message, loading: false })

			set({ loading: false })

			return data
		} catch (error) {
			set({ error: errorHandler(error), loading: false })
		}
	}
}))

export default useInterpreterStore
