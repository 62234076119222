import './Dashboard.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Store
import useUserStore from '../../../store/userStore'

const Dashboard = () => {
	// Translation Hook
	const { t } = useTranslation()

	const userStore = useUserStore()

	return (
		<main className="Dashboard">
			<h1 className="Admin_H1">{t('dashboard:header')}</h1>

			<p>Welcome {userStore?.user?.displayName}</p>
		</main>
	)
}

export default Admin(Dashboard)
