import { useState, useEffect } from 'react'
import './UserChartByLang.scss'

// API
import apiProtected from '../../../api/apiProtected'

// Chart
import { type BarDatum, ResponsiveBar } from '@nivo/bar'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'

const customTheme = {
	axis: {
		ticks: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12
			}
		},
		legend: {
			text: {
				fontFamily: 'Figtree, sans-serif',
				fontSize: 12
			}
		}
	},
	labels: {
		text: {
			fontFamily: 'Figtree, sans-serif'
		}
	}
}

const UserChartByLang = () => {
	// Local state
	const [loading, setLoading] = useState(true)
	const [chartData, setChartData] = useState<BarDatum[] | null>(null)

	// Fetch data on mount
	useEffect(() => {
		fetchData()
	}, [])

	// Fetch data from API
	const fetchData = async () => {
		try {
			setLoading(true)
			const response = await apiProtected.get('/admin/statistics/users-by-language')
			const data: BarDatum[] = response.data.data

			setChartData(data)
			setLoading(false)
		} catch (error) {
			setChartData([])
			console.error('Error fetching chart data:', error)
			setLoading(false)
		}
	}

	// Function to generate tick values
	const generateTickValues = (data: BarDatum[], stepSize = 1) => {
		// Ensure we're only dealing with numbers for max calculation
		const maxVal = Math.max(
			...data.map(item => {
				const value = item.count
				return typeof value === 'number' ? value : 0 // Skip or treat non-number as 0
			})
		)
		const size = Math.floor(maxVal / stepSize) + 1
		return Array.from({ length: size }, (_, index) => index * stepSize)
	}

	return (
		<div className="UserChartByLang">
			<h4 className="UserChartByLang_Header">Users by language</h4>
			<div className="UserChartByLang_Body">
				{loading && <Loading />}
				{!loading && chartData && (
					<div className="UserChartByLang_Body_Chart">
						<ResponsiveBar
							data={chartData}
							theme={customTheme}
							keys={['count']} // Changed from 'y' to 'count' to match your data keys
							indexBy="language" // Changed from 'x' to 'language'
							margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
							padding={0.3}
							valueScale={{ type: 'linear' }}
							indexScale={{ type: 'band', round: true }}
							colors={{ scheme: 'set3' }}
							borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
							axisTop={null}
							axisRight={null}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'Language', // Updated from 'Date' to 'Language'
								legendPosition: 'middle',
								legendOffset: 32
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'Subscribers', // This is correct as is, assuming 'count' represents number of subscribers
								legendPosition: 'middle',
								legendOffset: -40,
								tickValues: generateTickValues(chartData) // Set tick values to only integers
							}}
							labelSkipWidth={12}
							labelSkipHeight={12}
							labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
							legends={[]}
							animate={true}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default UserChartByLang
