// This Higher Order Component wrap pages with main tag.
import type { ComponentType, FC } from 'react'
import './Admin.scss'

// Header
import AdminNav from '../../organisms/adminNav/AdminNav'

// Separating out the type declarations
type ComponentProps = JSX.IntrinsicAttributes
type AdminWrapperProps<P> = P & ComponentProps

// This type means: given a Component P, return a new Component which also takes P as its props
function Admin<P extends ComponentProps>(PageComponent: ComponentType<P>): FC<P> {
	const PageWrapper: FC<AdminWrapperProps<P>> = props => {
		return (
			<div className="Admin">
				<AdminNav />

				{/* We spread the props here to pass them down to the PageComponent */}
				<PageComponent {...props} />
			</div>
		)
	}

	// This is a good practice to make debugging easier
	PageWrapper.displayName = `Admin(${PageComponent.displayName || PageComponent.name || 'Component'})`

	return PageWrapper
}

export default Admin
