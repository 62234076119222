import { useEffect, useState } from 'react'
import './Articles.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Router
import { useNavigate } from 'react-router-dom'

// Atoms
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import Select from '../../../ruya-shared/shared/ui/atoms/select/Select'
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import MaterialSymbol from '../../../ruya-shared/shared/ui/atoms/materialSymbol/MaterialSymbol'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import Pagination from '../../molecules/pagination/Pagination'

// Zustand
import useArticleStore from '../../../store/articleStore'

// Utils
import { formatDisplayDate } from '../../../utilities/dateHelper'
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

// Luxon
import { DateTime } from 'luxon'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'

// Types
import type { IArticleSearchParams } from '../../../@types/article'
import type { IArticle } from '../../../ruya-shared/shared/types'

const Articles = () => {
	// Router
	const navigate = useNavigate()

	// Post Search Params
	const initialIArticleSearchParams: IArticleSearchParams = {
		lang: languageSettings.defaultLanguage,
		status: 'all',
		page: 1,
		pageSize: 20,
		sort: 'desc'
	}

	// Search Params
	const [articleSearchParams, setArticleSearchParams] = useState<IArticleSearchParams>(initialIArticleSearchParams)

	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const articleStore = useArticleStore()

	// Get Posts
	useEffect(() => {
		articleStore.getArticles(articleSearchParams)
	}, [articleSearchParams])

	// Status filter change
	const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setArticleSearchParams({
			...articleSearchParams,
			status: e.target.value as IArticleSearchParams['status']
		})
	}

	// Language filter change
	const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setArticleSearchParams({
			...articleSearchParams,
			lang: e.target.value as IArticleSearchParams['lang']
		})
	}

	// Toggle sort
	const toggleSort = () => {
		setArticleSearchParams({
			...articleSearchParams,
			sort: articleSearchParams.sort === 'asc' ? 'desc' : 'asc'
		})
	}

	// Handle page change
	const handlePageChange = (page: number) => {
		setArticleSearchParams({
			...articleSearchParams,
			page
		})
	}

	// Create Article
	const handleCreateArticle = () => {
		navigate('/article/add')
	}

	// Loading
	if (articleStore.loading)
		return (
			<main className="Articles">
				<Loading />
			</main>
		)

	// Error
	if (articleStore.error)
		return (
			<main className="Articles">
				<InfoBox icon="true" type="error" text={articleStore.error} />
			</main>
		)

	return (
		<main className="Articles">
			<h1 className="Admin_H1">{t('admin:articles')}</h1>

			<div className="Articles_Bar">
				<Button text={t('button:createArticle')} color="success" onClick={handleCreateArticle} />
			</div>

			<div className="Articles_List">
				<div className="Articles_List_Info">
					{t('admin:totalRecords')} {articleStore.totalArticles} | {t('admin:pages')} {articleStore.page}/
					{articleStore.totalPages}
				</div>
				<table className="Articles_List_Table">
					<thead>
						<tr className="Articles_List_Row">
							<th className="Articles_List_Title">{t('admin:articleTitle')}</th>
							<th className="Articles_List_Date">
								{t('admin:articleDate')}
								{articleSearchParams.sort === 'desc' && <MaterialSymbol onClick={toggleSort} name="keyboard_arrow_up" />}
								{articleSearchParams.sort === 'asc' && <MaterialSymbol onClick={toggleSort} name="keyboard_arrow_down" />}
							</th>
							<th className="Articles_List_LastUpdate">{t('admin:articleLastUpdate')}</th>
							<th className="Articles_List_Status">
								<Select inputSize="xs" onChange={handleStatusChange} value={articleSearchParams.status}>
									<option value="all">{t('admin:articleStatusAll')}</option>
									<option value="draft">{t('admin:articleStatusDraft')}</option>
									<option value="published">{t('admin:articleStatusPublished')}</option>
									<option value="deleted">{t('admin:articleStatusDeleted')}</option>
									<option value="archived">{t('admin:articleStatusArchived')}</option>
								</Select>
							</th>
							<th className="Articles_List_Language">
								<Select
									inputSize="xs"
									onChange={handleLanguageChange}
									options={getActiveLanguages}
									labelField="displayName"
									valueField="isoCode"
									value={articleSearchParams.lang}
								/>
							</th>
						</tr>
					</thead>
					<tbody className="Articles_List_Body">
						{articleStore.articles.map((article: IArticle, index) => {
							const key = article?._id ? article._id.toString() : index

							return (
								<tr
									className="Articles_List_Body_Row"
									key={key}
									onClick={() => navigate(`/article/edit/${article._id}`)}>
									<th className="Articles_List_Title">{article.title}</th>
									<th className="Articles_List_Date">
										{formatDisplayDate(article.createdAt, DateTime.DATETIME_SHORT)}
									</th>
									<th className="Articles_List_LastUpdate">
										{formatDisplayDate(article.updatedAt, DateTime.DATETIME_SHORT)}
									</th>
									<th className="Articles_List_Status"> {article.status}</th>
									<th className="Articles_List_Language">{article.lang}</th>
								</tr>
							)
						})}
					</tbody>
				</table>
				{!articleStore.articles.length && <InfoBox icon="true" type="info" text={t('admin:noRecordsFound')} />}
			</div>

			<Pagination
				lang={articleSearchParams.lang}
				page={articleSearchParams.page || 0}
				totalPages={articleStore.totalPages || 0}
				onPageChange={handlePageChange}
			/>
		</main>
	)
}

export default Admin(Articles)
