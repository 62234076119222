// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Types
import type { IArticleDataState, IArticleResponse, IArticleSearchParams, IArticleState, IArticleTagResponse } from '../@types/article'
import type { IArticle } from '../ruya-shared/shared/types'

// Config
import languageSettings from '../ruya-shared/shared/config/languageSettings'

// Utils
import { apiErrorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Initial State
const initialState: IArticleDataState = {
	articles: [],
	tags: [],
	page: 1,
	totalPages: 0,
	totalArticles: 0,
	loading: false,
	error: null
}

// User Store
const useArticleStore = create<IArticleState>(set => ({
	...initialState,

	// Actions
	setLoading: (loading: boolean) =>
		set({
			loading,
			error: null
		}),
	setApiError: (error: string) =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),

	// Async actions
	getArticles: async (IArticleSearchParams: IArticleSearchParams) => {
		// Set loading to true
		set({ loading: true })

		// Get search params
		const { lang = languageSettings.defaultLanguage, status = 'all', page = 1, pageSize = 10, sort = 'desc' } = IArticleSearchParams

		// Build query string
		const queryString = `?lang=${lang}&status=${status}&page=${page}&pageSize=${pageSize}&sort=${sort}`

		try {
			// Get articles
			const { data } = await apiProtected.get(`/admin/article${queryString}`)

			// Axios data response
			const IArticleResponse = data as ApiResponse<IArticleResponse>

			if (IArticleResponse.status === 'success' && IArticleResponse.data) {
				// Save articles to state
				set({
					articles: IArticleResponse.data.articles,
					page: IArticleResponse.data.page,
					totalPages: IArticleResponse.data.totalPages,
					totalArticles: IArticleResponse.data.totalArticles,
					loading: false,
					error: null
				})
			} else {
				// Set error
				set({ loading: false, error: IArticleResponse.message })
			}
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
		}
	},
	addArticle: async (article: IArticle): Promise<ApiResponse<IArticleResponse>> => {
		// Set loading to true
		set({ loading: true })

		try {
			// Get articles
			const { data } = await apiProtected.post('/admin/article', article)

			// Axios data response
			const IArticleResponse = data as ApiResponse<IArticleResponse>

			if (IArticleResponse.status === 'success' && IArticleResponse.data) {
				// Set loading to false
				set({ loading: false, error: null })
			} else {
				// Set error
				set({ loading: false, error: IArticleResponse.message })
			}

			return IArticleResponse
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })

			return { status: 'error', message: apiErrorHandler(error) } as ApiResponse<IArticleResponse>
		}
	},
	updateArticle: async (article: IArticle): Promise<ApiResponse<IArticleResponse>> => {
		// Set loading to true
		set({ loading: true })

		try {
			// Get articles
			const { data } = await apiProtected.put('/admin/article', article)

			// Axios data response
			const IArticleResponse = data as ApiResponse<IArticleResponse>

			if (IArticleResponse.status === 'success' && IArticleResponse.data) {
				// Set loading to false
				set({ loading: false, error: null })
			} else {
				// Set error
				set({ loading: false, error: IArticleResponse.message })
			}

			return IArticleResponse
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })

			return { status: 'error', message: apiErrorHandler(error) } as ApiResponse<IArticleResponse>
		}
	},
	getArticleTags: async (lang: string) => {
		// Set loading to true
		set({ loading: true })

		try {
			// Get articles
			const { data } = await apiProtected.get(`/admin/article/tags?lang=${lang}`)

			// Axios data response
			const tagsResponse = data as ApiResponse<IArticleTagResponse>

			if (tagsResponse.status === 'success' && tagsResponse.data) {
				set({ loading: false, error: null, tags: tagsResponse.data.tags })
			} else {
				// Set error
				set({ loading: false, error: tagsResponse.message })
			}
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
		}
	}
}))

export default useArticleStore
