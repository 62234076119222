import type React from 'react'
import { useEffect } from 'react'

// Zustand
import useUserStore from '../store/userStore'

// React Router
import { Navigate, useLocation } from 'react-router-dom'

// Shared
import type { IPassportRole } from '../ruya-shared/shared/types'
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

type PrivateProps = {
	Component: React.ComponentType<any>
	roles?: IPassportRole[]
}

const Private = (props: PrivateProps) => {
	// Props
	const { Component, roles = ['admin', 'moderator', 'editor', 'support', 'developer'] as IPassportRole[] } = props

	// Router
	const { pathname } = useLocation()

	// Zustand
	const userStore = useUserStore()

	// Try to authenticate user with token. If token is invalid, user will be logged out
	useEffect(() => {
		if (!userStore.user) userStore.authenticateUserWithToken()
	}, [userStore.user])

	// If user is not active, redirect to account status page
	if (userStore.user?.isActive === false) {
		return (
			<Navigate to={commonSettings.apps.web.url + commonSettings.apps.web.paths.accountStatus} state={{ from: pathname }} replace />
		)
	}

	// If user is blocked, redirect to account status page
	if (userStore.user?.isBlocked?.status === true) {
		return (
			<Navigate to={commonSettings.apps.web.url + commonSettings.apps.web.paths.accountStatus} state={{ from: pathname }} replace />
		)
	}

	// Check if user has one of the required roles
	if (roles && userStore.user) {
		const hasRole = roles.some(role => userStore.user?.roles?.includes(role))
		if (!hasRole) {
			return <Navigate to={commonSettings.apps.web.url} replace /> // Redirect to webApp
		}
	}

	// Render component if user is authenticated and has required roles
	if (userStore.user) return <Component />
}

export default Private
