import './ProgressBar.scss'

// Localization
import { useTranslation } from 'react-i18next'

interface ProgressBarProps {
	total: number
	completed: number
	showPercentage?: boolean
}

const ProgressBar = (props: ProgressBarProps) => {
	const { total, completed, showPercentage = false } = props

	const percentage = (completed / total) * 100

	// Translation Hook
	const { t } = useTranslation()

	return (
		<div className="ProgressBar">
			<div className="ProgressBar_Progress" style={{ width: `${percentage}%` }}>
				<span className="ProgressBar_Percentage">{`${Math.floor(percentage)}`}%</span>
			</div>
		</div>
	)
}

export default ProgressBar
