import { useEffect, useState } from 'react'

const useScript = (url: string): boolean => {
	const [isLoaded, setIsLoaded] = useState(false)

	useEffect(() => {
		const script = document.createElement('script')

		script.src = url
		script.async = true
		script.onload = () => setIsLoaded(true) // Set state when script is loaded

		document.body.appendChild(script)

		return () => {
			document.body.removeChild(script)
		}
	}, [url])

	return isLoaded
}

export default useScript
