import admin from './admin.json'
import button from './button.json'
import common from './common.json'
import dashboard from './dashboard.json'
import dates from './dates.json'
import dialog from './dialog.json'
import errors from './errors.json'
import form from './form.json'
import info from './info.json'
import languages from './languages.json'
import links from './links.json'
import login from './login.json'
import options from './options.json'
import player from './player.json'
import validation from './validation.json'

const enGB = {
	admin,
	button,
	common,
	dashboard,
	dates,
	dialog,
	errors,
	form,
	info,
	languages,
	links,
	login,
	options,
	player,
	validation
}

export default enGB
