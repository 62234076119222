// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'

// Shared
import languageSettings from '../ruya-shared/shared/config/languageSettings'
import type { IPage } from '../ruya-shared/shared/types'
import { apiErrorHandler } from '../ruya-shared/shared/utils/errorHelper'

// Initial State
const initialState: IPageDataState = {
	pageList: [],
	loading: false,
	error: null
}

// User Store
const usePageStore = create<IPageState>(set => ({
	...initialState,

	// Actions
	setLoading: (loading: boolean) =>
		set({
			loading,
			error: null
		}),
	setApiError: (error: string) =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),

	// Async actions
	getPageList: async (pageSearchParams: IPageSearchParams) => {
		// Set loading to true
		set({ loading: true })

		// Get search params
		const { lang = languageSettings.defaultLanguage, sort = 'desc' } = pageSearchParams

		// Build query string
		const queryString = `?lang=${lang}&sort=${sort}`

		try {
			// Get page list
			const { data } = await apiProtected.get(`/admin/page${queryString}`)

			// Axios data response
			const pageListResponse = data as ApiResponse<IPageResponse>

			if (pageListResponse.status === 'success' && pageListResponse.data) {
				// Save page to state
				set({
					pageList: pageListResponse.data.pageList,
					loading: false,
					error: null
				})
			} else {
				// Set error
				set({ loading: false, error: pageListResponse.message })
			}
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
		}
	},
	addPage: async (page: IPage): Promise<ApiResponse<IPageResponse[]>> => {
		// Set loading to true
		set({ loading: true })

		try {
			// Save page and get page list
			const { data } = await apiProtected.post('/admin/page', page)

			// Axios data response
			const pageResponse = data as ApiResponse<IPageResponse[]>

			if (pageResponse.status === 'success' && pageResponse.data) {
				// Set loading to false
				set({ loading: false, error: null })
			} else {
				// Set error
				set({ loading: false, error: pageResponse.message })
			}

			return pageResponse
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })

			return { status: 'error', message: apiErrorHandler(error) } as ApiResponse<IPageResponse[]>
		}
	},
	updatePage: async (page: IPage): Promise<ApiResponse<IPageResponse[]>> => {
		// Set loading to true
		set({ loading: true })

		try {
			// Update page and get page list
			const { data } = await apiProtected.put('/admin/page', page)

			// Axios data response
			const pageResponse = data as ApiResponse<IPageResponse[]>

			if (pageResponse.status === 'success' && pageResponse.data) {
				// Set loading to false
				set({ loading: false, error: null })
			} else {
				// Set error
				set({ loading: false, error: pageResponse.message })
			}

			return pageResponse
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })

			return { status: 'error', message: apiErrorHandler(error) } as ApiResponse<IPageResponse[]>
		}
	}
}))

export default usePageStore
