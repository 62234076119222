// About Ruya
const aboutRuya = (): string => `Reference Information for Writing (Do not include directly in the article):
Ruya is more than just a dream and sleep app; it's a sanctuary for dreamers and sleep enthusiasts available on the web, iPhone, and Android platforms. At its core, Ruya offers everyone a complimentary dream journal and a subscription-based, AI-driven dream interpretation tool that allows interpretations from scientific, religious, and spiritual perspectives. Beyond the app, Ruya also hosts a comprehensive article knowledge hub rich with insights and research on dreams and sleep, available in multiple languages to connect dreamers worldwide`

// Article writing rules
const articleWritingRules = (lang: string, targetLanguageName: string): string => `
Guidelines for Crafting the Article:

- Feature Integration: When discussing the importance of recording dreams or interpreting them, subtly integrate Ruya’s features like the free dream journal and customized interpretation options, ensuring these mentions feel organic and relevant to the content.
- Engaging Introductions: Start each article with a unique hook such as a surprising fact, a thought-provoking question, or a compelling quote. Vary your approaches to engage different reader interests right from the beginning.
- Writing Style: Emulate the engaging and conversational style of Belle Beth Cooper. Refer to her blog posts at Belle Beth Cooper's Blog for style nuances.
- Content Originality: Ensure the content is original and cannot be easily identified as AI-generated by common content detectors. Avoid plagiarism at all costs.
- Structure and Flow: Articles should be well-structured with logical organization. Use transition words to enhance readability and flow. Employ bullet points and numbered lists where relevant to make content scannable.
- SEO Optimization: Develop SEO-optimized titles (50-60 characters) and descriptions (156 characters) that are intriguing yet reflective of the content. Incorporate key phrases naturally throughout the article, using variations to enhance readability and SEO.
- Persuasive Elements: Use persuasive statistics, analogies, metaphors, anecdotes, and quotes to enrich the content. These elements should lend credibility and engage the reader’s imagination.
- Personalized Language: Use the word "you" to create a direct connection with the reader, making the article more engaging and personal.
- Visual Language: Employ vivid, sensory descriptions to evoke mental imagery, making your points more memorable and impactful.
- Creative Section Headings: Avoid generic headings like "Conclusion." Instead, use creative and engaging titles for each section to maintain reader interest.
- Article Specifications: Articles must have a minimum length of 1000 words or 7500 characters.
- Include a Blockquote: To emphasize a particularly striking or insightful statement, include a blockquote in your content. Below is the format you should use for structuring a blockquote. First <p> tag should contain the quote, and the second <p> tag should contain the author’s name.
<blockquote>
    <p>[Insert the relevant quote here.]</p>
    <p>[Author’s Name]</p>
</blockquote>
- Technical JSON Formatting: Ensure all outputs are in strict RFC8259 compliant JSON format. This includes clearly formatted fields for slug, title, description, content, and metadata.

Title Creativity and Guidance:

Inspire Variation: Use the following examples as inspiration to craft unique and engaging titles. These are not templates but starting points to spark creativity. Writers are encouraged to develop their own phrases that capture the essence of the article while avoiding repetition:
"Decoding the Secrets of..."
"A Closer Look at..."
"Behind the Science of..."
"The Hidden World of..."
"Shedding Light on..."
"Discover the Impact of..."
"How [Subject] Changes Everything"
"Unpacking the Mysteries of..."
"The Truth About..."
"Breaking Down the Complexities of..."
Encourage Fresh Phrasing: While these examples show various ways to start a title, writers should feel free to invent similar patterns that better suit their specific article content and the unique insights they wish to highlight.

Ensuring Factual Accuracy in Articles:

Sources and Citation: All factual information must be verified with credible sources. Authors are required to provide references for all claims and theories discussed. Acceptable sources include academic journals, authoritative books, and reputable websites. Authors should ensure that citations are properly formatted according to your publication standards.
Fact-Checking: Authors are required to rigorously fact-check their content before submission. This includes verifying the accuracy of dates, quotes, scientific claims, and historical facts using credible and authoritative sources.
Direct Quotations: When specific theories, quotes, or data are discussed, direct quotations from the original sources should be used to ensure the accuracy of the information presented. If direct quotations are not available, the information should be paraphrased accurately and sourced appropriately.
Editorial Review: All articles will undergo an editorial review that focuses on factual accuracy, adherence to sourcing requirements, and overall content quality. This process is designed to ensure that all content is informative, accurate, and reliable.
Educational Expertise: Contributions from writers with relevant educational backgrounds or expertise in the subject matter are highly encouraged. Such expertise can lend additional credibility and depth to the analysis and discussion of topics.
Avoiding Fabrication: Authors should avoid making unsupported assertions or fabricating details. All information presented as fact must be traceable to a reliable source.
`

// Output format
const expectedOutputFormat = (tags: string): string => `
Expected Output Format:

⚠️ CRUCIAL: 
- Article length should be a minimum of 1000 words or 7500 characters.

Field definitions:
1- **slug**: Up to 69 characters, forming a valid, SEO-friendly URL slug.
2- **title**: SEO-optimized, intriguing, and reflective of the content, with a maximum of 60 characters.
3- **description**: SEO-optimized, designed to attract clicks, with a maximum of 156 characters.
4- **content**: Comply with HTML5 standards (e.g., H1, H2, H3, p, ul, ol, bold, italics, blockquotes) for an SEO-friendly and easily scannable article.
5- **readingTime**: Estimate in minutes.
6- **tags**: Use tags related from this reference: ${tags} (formatted as 'tag name (slug)'). You can add new relevant tags but avoid unrelated to include unrelated ones.
7- **references**: Provide detailed references in APA format.

JSON Response Format:
{"slug": "", "title": "", "description": "", "content": "", "readingTime": "0", "tags": [{"name": "", "slug": "" }], "references": [{"author": "", "year": "", "title": "", "publisherOrJournal": "", "volume": "", "issue": "", "link": ""}]}
`

// Article creation prompt
export const articlePrompt = (lang: string, targetLanguageName: string, tags: string): string => {
	// Article prompt
	const tmpArticlePrompt = `${aboutRuya()}
${articleWritingRules(lang, targetLanguageName)}
${expectedOutputFormat(tags)}
`
	return tmpArticlePrompt
}
