import { useEffect, useState } from 'react'
import './EditArticleImages.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// Atoms
import Loading from '../../../ruya-shared/shared/ui/atoms/loading/Loading'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import { FormFieldSet } from '../../../ruya-shared/shared/ui/atoms/form/Form'

// Molecules
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'
import AdminArticleNavigation from '../../molecules/adminArticleNavigation/AdminArticleNavigation'
import ImageCropper from '../../molecules/imageCropper/ImageCropper'

// App Settings
import appSettings from '../../../appSettings'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Shared
import type { IArticle } from '../../../ruya-shared/shared/types'
import { commonSettings } from '../../../ruya-shared/shared/config/commonSettings'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditArticleImages = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [post, setPost] = useState<IArticle | null>(null)

	// Get Post by ID with api call
	useEffect(() => {
		if (!id) navigate('/article')
		getPost()
	}, [id])

	const coverImage = appSettings.admin.post.imageSizes.find(image => image.name === 'cover')

	// Get Post by ID
	const getPost = async () => {
		setIsLoading(true)

		try {
			const response = await apiProtected.get(`admin/article/${id}`)

			if (response.data.status === 'success') {
				const articleData = response.data.data as IArticle
				// Update state
				setPost(articleData)
				setIsLoading(false)
			} else {
				setApiError(response.data.message)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			setApiError(apiErrorHandler(error))
		}
	}

	// Upload finished
	const handleUploadFinished = () => {
		getPost()
	}

	// Loading
	if (isLoading)
		return (
			<div className="EditArticleImages">
				<Loading />
			</div>
		)

	return (
		<main className="EditArticleImages">
			<h1 className="Admin_H1">{t('admin:headerEditArticle')}</h1>

			<HistoryBackLink />

			<InfoBox icon="true" type="info" text={`http://ruya.co/${post?.lang}/articles/${post?.slug}`} />

			<AdminArticleNavigation id={id} lang={post?.lang} />

			{apiError && <InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />}

			<div className="EditArticleImages_Settings">
				<FormFieldSet className="AdminEditArticleSettings_BulkOperations" legend={t('admin:images')}>
					{post?.images ? (
						<div className="EditArticleImages_Images">
							<div>
								<span>{t('admin:coverImage')}</span>
								{<img src={commonSettings.apps.cdn.url + '/' + post?.images?.cover} alt={t('admin:coverImage')} />}
							</div>
							<div>
								<span>{t('admin:listingImage')}</span>
								{<img src={commonSettings.apps.cdn.url + '/' + post?.images?.list} alt={t('admin:listingImage')} />}
							</div>
							<div>
								<span>{t('admin:thumbnailImage')}</span>
								{<img src={commonSettings.apps.cdn.url + '/' + post?.images?.thumbnail} alt={t('admin:thumbnailImage')} />}
							</div>
						</div>
					) : (
						<>
							<InfoBox type="warning" icon="true" text={t('admin:imageNameWarning')} />
							<InfoBox
								type="info"
								icon="true"
								text={t('admin:noImages', { minImageSize: coverImage?.width + 'x' + coverImage?.height })}
							/>
						</>
					)}
				</FormFieldSet>

				<ImageCropper
					minWidth={coverImage?.width}
					minHeight={coverImage?.height}
					savePath={`content/${id}`}
					onFinish={handleUploadFinished}
				/>
			</div>
		</main>
	)
}

export default Admin(EditArticleImages)
