// Zustand
import { create } from 'zustand'

// API
import apiProtected from '../api/apiProtected'
import apiPublic from '../api/apiPublic'

// Shared
import { getLocalStorage, setLocalStorage } from '../ruya-shared/shared/utils/storageHelper'
import { apiErrorHandler } from '../ruya-shared/shared/utils/errorHelper'
import { commonSettings } from '../ruya-shared/shared/config/commonSettings'

// Initial State
const initialState: StatisticsDataState = {
	statistics: null,
	loading: false,
	error: null
}

// Statistics Store
const useStatisticsStore = create<StatisticsState>((set, get) => ({
	...initialState,

	// Actions
	setLoading: (loading: boolean) =>
		set({
			loading,
			error: null
		}),
	setApiError: (error: string) =>
		set({
			loading: false,
			error
		}),
	reset: () => set(initialState),
	loadStatistics: async () => {

		// If statistics already loaded, return
		if (get().statistics) return

		// Set loading to true
		set({ loading: true })

		try {
			// Get statistics
			const { data } = await apiProtected.get('/admin/statistics')

			// Axios data response
			const statisticsResponse = data as ApiResponse<any>

			if (statisticsResponse.status === 'success' && statisticsResponse.data) {
				// Save statistics to state
				set({ statistics: statisticsResponse.data, loading: false, error: null })
			} else {
				// Set error
				set({ loading: false, error: statisticsResponse.message })
			}
		} catch (error) {
			// Set error
			set({ loading: false, error: apiErrorHandler(error) })
		}
	}
}))

export default useStatisticsStore
