import { useEffect, useState } from 'react'
import './EditTheory.scss'

// Templates
import Admin from '../../templates/admin/Admin'

// Localization
import { useTranslation } from 'react-i18next'

// React Hook Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DevTool } from '@hookform/devtools'

// Atoms
import { Form, FormFieldSet, FormGroup } from '../../../ruya-shared/shared/ui/atoms/form/Form'
import Button from '../../../ruya-shared/shared/ui/atoms/button/Button'
import HistoryBackLink from '../../../ruya-shared/shared/ui/atoms/historyBackLink/HistoryBackLink'
import Switch from '../../../ruya-shared/shared/ui/atoms/switch/Switch'
import Input from '../../../ruya-shared/shared/ui/atoms/input/Input'
import TextArea from '../../../ruya-shared/shared/ui/atoms/textArea/TextArea'

// Molecules
import TheoryNavigation from '../../molecules/theoryNavigation/TheoryNavigation'
import InfoBox from '../../../ruya-shared/shared/ui/molecules/infoBox/InfoBox'

// Zustand
import useInterpreterStore from '../../../store/interpreterStore'

// Router
import { useNavigate, useParams } from 'react-router-dom'

// API
import apiProtected from '../../../api/apiProtected'

// Toastify
import { toast } from 'react-toastify'

// Utils
import { getActiveLanguages } from '../../../ruya-shared/shared/utils/languageHelper'

// Types
import type { IInterpreter } from '../../../ruya-shared/shared/types'

// Validation
import { interpreterSchema } from '../../../validation/interpreter'

// Config
import languageSettings from '../../../ruya-shared/shared/config/languageSettings'
import { confirmAlert } from 'react-confirm-alert'
import { apiErrorHandler } from '../../../ruya-shared/shared/utils/errorHelper'

const EditTheory = () => {
	// Translation Hook
	const { t } = useTranslation()

	// Zustand
	const interpreterStore = useInterpreterStore()

	// Router
	const navigate = useNavigate()
	const { id } = useParams()

	// API Error State
	const [apiError, setApiError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [interpreter, setContent] = useState<IInterpreter | null>(null)

	// Get language object
	const languageObject = getActiveLanguages.find(lang => lang.isoCode === interpreter?.language)

	// Is default language
	const isDefaultLanguage = interpreter?.language === languageSettings.defaultLanguage

	// Input direction (ltr or rtl) depending on the language
	const dir = languageObject?.direction || 'ltr'

	const initialFormValues = {
		_id: '',
		language: '',
		name: '',
		description: '',
		focus: '',
		benefit: '',
		prompt: '',
		icon: '',
		active: '',
		menuOrder: 0
	}
	const formOptions = { mode: 'onChange', defaultValues: initialFormValues, resolver: yupResolver(interpreterSchema) } as any
	const {
		register,
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors }
	} = useForm<IInterpreter>(formOptions)

	// Get Content by ID with api call
	useEffect(() => {
		if (!id) navigate('/theories')
		getInterpreter()
	}, [id])

	// Get interpreter by ID
	const getInterpreter = async () => {
		const response = await apiProtected.get(`admin/interpreter/details/${id}`)

		if (response.data.status === 'success') {
			const interpreterData = response.data.data as IInterpreter

			// Update state
			setContent(interpreterData)

			// Set default values
			reset({
				_id: interpreterData._id,
				language: interpreterData.language,
				name: interpreterData.name,
				description: interpreterData.description,
				focus: interpreterData.focus,
				benefit: interpreterData.benefit,
				prompt: interpreterData.prompt,
				icon: interpreterData.icon,
				active: interpreterData.active,
				menuOrder: interpreterData.menuOrder
			})
		} else {
			navigate('/theories')
		}
	}

	// Form submit
	const onSubmit = async (values: IInterpreter) => {
		// Set loading
		setIsLoading(true)

		// Clear API error
		setApiError(null)

		// Add ID to values
		values._id = id

		// Update content
		const response = await interpreterStore.updateInterpreter(values)

		if (response.status === 'success' && response.data) {
			const interpreterData = response.data as unknown as IInterpreter

			// Show success message
			toast.success('Content updated!', {
				position: 'bottom-center',
				autoClose: 2000
			})

			// Update state
			setContent(interpreterData)

			// Set default values
			reset({
				_id: interpreterData._id,
				language: interpreterData.language,
				name: interpreterData.name,
				description: interpreterData.description,
				focus: interpreterData.focus,
				benefit: interpreterData.benefit,
				prompt: interpreterData.prompt,
				icon: interpreterData.icon,
				active: interpreterData.active,
				menuOrder: interpreterData.menuOrder
			})
		} else {
			setApiError(response.message || t('error:unexpectedError'))
		}
		setIsLoading(false)
	}

	// Handle delete click
	const handleDelete = async () => {
		confirmAlert({
			title: t('dialog:warningTitle'),
			message: t('dialog:deleteTheory'),
			buttons: [
				{
					label: t('button:yes'),
					onClick: () => deleteTheory(),
					className: 'button--danger'
				},
				{
					label: t('button:cancel'),
					onClick: () => {
						return false
					}
				}
			]
		})
	}

	// Delete post
	const deleteTheory = async () => {
		try {
			const response = await apiProtected.delete(`admin/interpreter/${id}`)

			if (response?.data?.status === 'success') {
				// Redirect to post list
				navigate('/theories')
			} else {
				// Show error message
				toast.error(response.data.message || t('error:unexpectedError'), {
					position: 'bottom-center',
					autoClose: 2000
				})
			}
		} catch (error) {
			// Show error message
			toast.error(apiErrorHandler(error) || t('error:unexpectedError'), {
				position: 'bottom-center',
				autoClose: 2000
			})
		}
	}

	return (
		<main className="EditTheory">
			<h1 className="Admin_H1">{t('admin:headerEditPage')}</h1>

			<HistoryBackLink />

			<TheoryNavigation id={id} lang={interpreter?.language} />

			<Form className="EditTheory_Form" onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup twoColumn={true}>
					<Switch label={t('form:isActive.label')} defaultValue={watch('active')} {...register('active')} />
				</FormGroup>

				<FormGroup fourColumn={true}>
					<Input
						inputSize="sm"
						icon="translate"
						type="text"
						placeholder={t('form:language.placeholder')}
						label={t('form:language.label')}
						invalid={Boolean(errors?.language?.message)}
						invalidMessage={errors?.language?.message?.toString()}
						disabled={true}
						{...register('language')}
					/>

					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						placeholder={t('form:title.placeholder')}
						label={t('form:title.label')}
						invalid={Boolean(errors?.name?.message)}
						invalidMessage={errors?.name?.message?.toString()}
						{...register('name')}
					/>

					<Input
						inputSize="sm"
						dir={dir}
						type="text"
						showTooltip={true}
						tooltipText="https://fonts.google.com/icons"
						icon={watch('icon')}
						placeholder={t('form:icon.placeholder')}
						label={t('form:icon.label')}
						invalid={Boolean(errors?.icon?.message)}
						invalidMessage={errors?.icon?.message?.toString()}
						{...register('icon')}
					/>

					<Input
						inputSize="sm"
						type="number"
						placeholder={t('form:menuOrder.placeholder')}
						label={t('form:menuOrder.label')}
						invalid={Boolean(errors?.menuOrder?.message)}
						invalidMessage={errors?.menuOrder?.message?.toString()}
						{...register('menuOrder')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:description.label')}
						invalid={Boolean(errors?.description?.message)}
						invalidMessage={errors?.description?.message?.toString()}
						{...register('description')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:focus.label')}
						invalid={Boolean(errors?.focus?.message)}
						invalidMessage={errors?.focus?.message?.toString()}
						{...register('focus')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:benefit.label')}
						invalid={Boolean(errors?.benefit?.message)}
						invalidMessage={errors?.benefit?.message?.toString()}
						{...register('benefit')}
					/>
				</FormGroup>

				<FormGroup>
					<TextArea
						dir={dir}
						rows={5}
						resize="vertical"
						label={t('form:prompt.label')}
						invalid={Boolean(errors?.prompt?.message)}
						invalidMessage={errors?.prompt?.message?.toString()}
						{...register('prompt')}
					/>
				</FormGroup>

				{apiError && (
					<FormGroup>
						<InfoBox icon="true" type="error" text={apiError || t('error:unexpectedError')} />
					</FormGroup>
				)}

				<FormGroup className="FormGroup_Submit">
					<Button text={t('button:save')} color="success" loading={isLoading} type="submit" disabled={isLoading} />
				</FormGroup>
			</Form>

			{isDefaultLanguage && (
				<FormGroup>
					<FormFieldSet className="EditArticleSettings_DangerZone" legend={t('admin:dangerZone')}>
						<p>{t('admin:deleteAllTheoryDesc')}</p>
						<Button text={t('button:deleteAll')} color="error" disabled={isLoading} onClick={handleDelete} />
					</FormFieldSet>
				</FormGroup>
			)}

			{process.env.NODE_ENV === 'development' && <DevTool control={control} />}
		</main>
	)
}

export default Admin(EditTheory)
